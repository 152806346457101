import {
    ArrayField,
    AutocompleteInput,
    BooleanInput,
    Datagrid,
    DateField,
    Edit,
    FormTab,
    Labeled,
    ListButton,
    maxValue,
    minValue,
    number,
    NumberField,
    ReferenceInput,
    required,
    TabbedForm,
    TextField,
    TextInput,
    TopToolbar,
    UrlField,
    useRecordContext,
    ReferenceManyField,
    Button,
    Link,
    ChipField,
    ReferenceField, Pagination, BooleanField, FunctionField, useDataProvider, useRefresh
} from "react-admin";
import * as React from "react";
import MargeNumberField from "./MargeNumberField";
import {Grid, Typography} from "@mui/material";
import {CustomNumberInput} from "../CustomNumberInput";
import {useMutation} from "react-query";

const validateName = required();
const validateSellingPrice = [required(), number(), minValue(0)];
const validatePurchasePrice = [number(), minValue(0)];
const validatePurchaseShippingPrice = [number(), minValue(0)];
const validateRetailPrice = [number(), minValue(0)];
const validateDealerDiscountPercent = [number(), minValue(0), maxValue(100)];
const validateLength = [number(), minValue(0)];
const validateWidth = [number(), minValue(0)];
const validateHeight = [number(), minValue(0)];
const validateWeight = [number(), minValue(0)];

const SyncMagentoStockButton = ({ productId  }) => {
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const { mutate, isLoading } = useMutation(
        ['productId', productId],
        () => dataProvider.syncMagentoStock(productId),
        {
            onSuccess: (data) => {
                refresh();
            }
        }
    );
    return (<Button label="Sync Magento Stock" onClick={() => { mutate(); } } disabled={isLoading}/>);
};

const ProductEditActions = () => {
    const record = useRecordContext();
    return (
        <TopToolbar>
            <ListButton/>
            {record && (record.onCustomerWebshop || record.onDealerWebshop) && (
                <SyncMagentoStockButton productId={record.id}/>
            )}
        </TopToolbar>
    )
};

const ProductTitle = () => {
    const record = useRecordContext();
    return <span>Product {record ? `"${record.name}"` : ''}</span>;
};

const AddNewInventoryAdjustmentButton = ({ }) => {
    const record = useRecordContext();
    return (<Button
            variant="contained"
            component={Link}
            to={{
                pathname: "/inventoryAdjustments/create",
                // Here we specify the initial record for the create view
                state: { record: { product_id: record.id } },
            }}
            label="Adjust Stock"
        />
    );
}

const AddNewPurchaseItemButton = () => {
    const record = useRecordContext();
    return (<Button
            variant="contained"
            component={Link}
            to={{
                pathname: "/productCores/create",
                // Here we specify the initial record for the create view
                state: { record: { product: {id : record.id } } },
            }}
            label="Add Core"
        />
    );
}

const ProductEdit = props => {
    const record = useRecordContext();
    return (
        <Edit {...props} title={<ProductTitle/>} actions={<ProductEditActions/>} redirect="edit">
            <TabbedForm>
                <FormTab label="Product Info">
                    <TextInput source="sku" InputProps={{ disabled: true }} />
                    <TextInput source="skuSupplier"/>
                    <TextInput source="name" validate={validateName} fullWidth/>
                    <Labeled label="Brand">
                        <ReferenceField source="brand.id" reference="brands" sort={{field: 'name', order: 'ASC'}} />
                    </Labeled>
                    <Labeled label="ProductType">
                        <ReferenceField label="ProductType" source="productType.id" reference="productTypes"
                                    sort={{field: 'name', order: 'ASC'}}/>
                    </Labeled>
                    <Labeled label="Model">
                        <ReferenceField label="Model" source="model.id" reference="models"
                                    sort={{field: 'name', order: 'ASC'}}/>
                    </Labeled>
                    <TextInput source="number" InputProps={{ disabled: true }}/>
                    <TextInput source="remark" multiline fullWidth/>
                    <UrlField source="url" target="_blank"/>
                    <TextInput source="url" fullWidth/>
                    <ReferenceInput label="Country" source="countryOfManufacture" reference="countries" helperText={false}>
                        <AutocompleteInput source="countryOfManufacture" sx={{ width: 250 }} helperText={false}/>
                    </ReferenceInput>
                    <TextInput source="hsCode"/>
                    <CustomNumberInput source="additionalUnits"/>
                    <BooleanInput source="onCustomerWebshop"/>
                    <BooleanInput source="onDealerWebshop"/>
                    <TextInput source="productTypeWebshop" InputProps={{ disabled: true }}/>
                    <CustomNumberInput source="length" validate={validateLength} helperText="Value in cm"/>
                    <CustomNumberInput source="width" validate={validateWidth} helperText="Value in cm"/>
                    <CustomNumberInput source="height" validate={validateHeight} helperText="Value in cm"/>
                    <CustomNumberInput source="weight" validate={validateWeight} helperText="Value in kg"/>
                    <BooleanInput source="featured"/>
                    <BooleanInput source="active"/>
                    <Labeled label="Created At">
                        <DateField source="createdAt" showTime InputProps={{ disabled: true }}/>
                    </Labeled>
                    <Labeled label="Updated At">
                        <DateField source="updatedAt" showTime InputProps={{ disabled: true }}/>
                    </Labeled>
                </FormTab>
                <FormTab label="Prices">
                    <CustomNumberInput source="sellingPrice" validate={validateSellingPrice}/>
                    <Labeled label="Dealer Selling Price">
                        <NumberField source="dealerSellingPrice" options={{style: 'currency', currency: 'EUR'}}/>
                    </Labeled>
                    <CustomNumberInput source="retailPrice" validate={validateRetailPrice}/>
                    <AutocompleteInput source="retailCurrency" choices={[
                        {id: 'EUR', name: 'EUR'},
                        {id: 'USD', name: 'USD'},
                        {id: 'GBP', name: 'GBP'}
                    ]}/>
                    <CustomNumberInput source="purchasePrice" validate={validatePurchasePrice}/>
                    <AutocompleteInput source="purchaseCurrency" choices={[
                        {id: 'EUR', name: 'EUR'},
                        {id: 'USD', name: 'USD'},
                        {id: 'GBP', name: 'GBP'}
                    ]}/>
                    <CustomNumberInput source="purchaseShippingPrice" validate={validatePurchaseShippingPrice}/>
                    <AutocompleteInput source="purchaseShippingCurrency" choices={[
                        {id: 'EUR', name: 'EUR'},
                        {id: 'USD', name: 'USD'},
                        {id: 'GBP', name: 'GBP'}
                    ]}/>
                    <CustomNumberInput source="dealerDiscountPercent" validate={validateDealerDiscountPercent}/>
                    <Labeled label="Total Purchase Price">
                        <NumberField source="totalPurchasePrice" options={{style: 'currency', currency: 'EUR'}}/>
                    </Labeled>
                    <Labeled label="Margin">
                        <MargeNumberField source="margin" dealerField={false}/>
                    </Labeled>
                    <Labeled label="Dealer Margin">
                        <MargeNumberField source="dealerMargin" dealerField={true}/>
                    </Labeled>
                    <ArrayField source="options" sort={{ field: 'id', order: 'ASC' }}>
                        <Datagrid bulkActionButtons={false}>
                            <TextField source="name"/>
                            <ArrayField source="values" sort={{ field: 'id', order: 'ASC' }}>
                                <Datagrid bulkActionButtons={false}>
                                    <TextField source="name"/>
                                    <NumberField source="price" options={{style: 'currency', currency: 'EUR'}}/>
                                    <TextField source="skuExtension"/>
                                </Datagrid>
                            </ArrayField>
                        </Datagrid>
                    </ArrayField>
                </FormTab>
                <FormTab label="Stock">
                    <TextInput source="stockPosition"/>
                    <BooleanInput source="stockManagement" />
                    <BooleanInput source="inStock" label="In stock in webshop"/>
                    <CustomNumberInput source="reorderPoint"/>
                    <ReferenceInput label="Supplier" source="supplier.id" reference="relations"
                                    filter={{supplier: true, active: true}} sort={{field: 'company', order: 'ASC'}}>
                        <AutocompleteInput optionText="company" sx={{minWidth: 300}}/>
                    </ReferenceInput>
                    <Typography variant="h6" >Accounting Stock</Typography>
                    <Grid container spacing={2} columns={10}>
                        <Grid item xs={2}>
                            <Typography>Stock on Hand</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <NumberField source="accountingStock"/>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>Commited Stock</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <NumberField source="commitedAccountingStock"/>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>Available for Sale</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <NumberField source="availableAccountingStock"/>
                        </Grid>
                    </Grid>
                    <br/>
                    <Typography variant="h6" >Physical Stock</Typography>
                    <Grid container spacing={2} columns={10}>
                        <Grid item xs={2}>
                            <Typography>Stock on Hand</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <NumberField source="physicalStock"/>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>Commited Stock</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <NumberField source="commitedPhysicalStock"/>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>Available for Sale</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <NumberField source="availablePhysicalStock"/>
                        </Grid>
                    </Grid>
                </FormTab>
                <FormTab label="Stock Adjustments">
                    <Typography variant="h6">Adjustments</Typography>
                    <ReferenceManyField reference="inventoryAdjustments" target="product_id" sort={{ field: "createdAt", order: "DESC" }}
                                        perPage={10} pagination={<Pagination />}>
                        <Datagrid bulkActionButtons={false}>
                            <TextField source="amount"/>
                            <ChipField source="reason.name" />
                            <DateField source="createdAt"/>
                            <ReferenceField reference="orders" source="order.id" sortable={false}/>
                            <TextField source="order.state" sortable={false}/>
                            <ReferenceField label="Order Customer" reference="relations" source="order.customer.id" link={false} sortable={false}/>
                            <ReferenceField reference="purchases" source="purchase.id" link="show" sortable={false}/>
                            <BooleanField source="purchase.received" sortable={false}/>
                            <BooleanField source="purchase.invoiced" sortable={false}/>
                            <TextField source="remark"/>
                        </Datagrid>
                    </ReferenceManyField>
                    <br/>
                    <AddNewInventoryAdjustmentButton/>
                </FormTab>
                <FormTab label="Cores">
                    <Typography variant="h6">Cores</Typography>
                    <ReferenceManyField reference="productCores" target="product_id" sort={{ field: "createdAt", order: "DESC" }}
                                        perPage={10} pagination={<Pagination />}>
                        <Datagrid bulkActionButtons={false}>
                            <ReferenceField reference="purchases" source="purchaseItem.purchase.id" link="show" sortable={false}/>
                            <NumberField source="amount"/>
                            <NumberField source="amountSentBack"/>
                            <NumberField source="amountCredited"/>
                            <NumberField source="price" />
                            <TextField source="priceCurrency" />
                            <DateField source="createdAt"/>
                            <DateField source="updatedAt"/>
                        </Datagrid>
                    </ReferenceManyField>
                    <br/>
                    <AddNewPurchaseItemButton/>
                </FormTab>
                <FormTab label="Orders">
                    <Typography variant="h6">Orders</Typography>
                    <ReferenceManyField reference="orderItems" target="product_id" sort={{ field: "createdAt", order: "DESC" }}
                                        perPage={10} pagination={<Pagination />}>
                        <Datagrid bulkActionButtons={false}>
                            <DateField label="Created at" source="order.createdAt" sortable={false}/>
                            <ReferenceField reference="orders" source="order.id" sortable={false} label="Order number"/>
                            <ChipField label="State" source="order.state" sortable={false}/>
                            <BooleanField source="order.paid" sortable={false}/>
                            <BooleanField source="order.invoiced" sortable={false}/>
                            <ReferenceField label="Customer" reference="relations" source="order.customer.id" link={false} sortable={false}/>
                            <NumberField source="qty" sortable={false}/>
                            <BooleanField source="dropShipment"/>
                        </Datagrid>
                    </ReferenceManyField>
                </FormTab>
                <FormTab label="Purchases">
                    <Typography variant="h6">Purchases</Typography>
                    <ReferenceManyField reference="purchaseItems" target="product_id" sort={{ field: "id", order: "DESC" }}
                                        perPage={10} pagination={<Pagination />}>
                        <Datagrid bulkActionButtons={false}>
                            <DateField label="Order Date" source="purchase.orderDate" sortable={false}/>
                            <ReferenceField reference="purchases" source="purchase.id" sortable={false} label="Purchase number" link="show"/>
                            <ChipField label="State" source="purchase.state" sortable={false}/>
                            <BooleanField source="purchase.received" sortable={false}/>
                            <BooleanField source="purchase.invoiced" sortable={false}/>
                            <ReferenceField label="Supplier" reference="relations" source="purchase.supplier.id" link={false} sortable={false}>
                                <FunctionField
                                    label="Company"
                                    sortBy="company"
                                    render={record => `${record.company}`}
                                />
                            </ReferenceField>
                            <NumberField source="qty" sortable={false}/>
                            <NumberField source="qtyReceived" sortable={false}/>
                        </Datagrid>
                    </ReferenceManyField>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
}

export default ProductEdit;