import * as React from "react";
import {
    TextInput,
    Create,
    ReferenceInput,
    SimpleForm, AutocompleteInput, required,
} from "react-admin";
import {useLocation} from "react-router";

export const OrderCommentCreate = (...props) => {
    // Read the orderId from the location
    const location = useLocation();
    const orderId =
        location.state && location.state.record
            ? location.state.record.orderId
            : undefined;

    const redirect = orderId ? `/orders/${orderId}` : false;

    return (
        <Create {...props} redirect={redirect}>
            <SimpleForm>
                <ReferenceInput label="Order" source="orderId" reference="orders">
                    <AutocompleteInput validate={[required()]}/>
                </ReferenceInput>
                <TextInput source="description"/>
            </SimpleForm>
        </Create>
    )
};