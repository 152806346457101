import React, {useMemo, useState} from "react";
import {Card, CardContent, CardHeader} from "@mui/material";
import {useGetList} from "react-admin";
import {format, parseISO, startOfDay, subYears} from "date-fns";
import {Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";

const InventoryValue = () => {
    const aYearAgo = useMemo(() => subYears(startOfDay(new Date()), 1), []);
    const [date, setDate] = useState(aYearAgo.toISOString());

    const { data: inventoryValues } = useGetList('inventoryValues', {
        filter: { date_gte: (date ? format(parseISO(date), 'yyyy-MM-dd') : null) },
        sort: { field: 'date', order: 'ASC' },
        pagination: { page: 1, perPage: 400 },
    });

    if (!inventoryValues) return null;

    const getData = (inventoryValues): [] => {
        return  Array.from(inventoryValues).map(c => ({
            date: c.date,
            value: c.value,
        }));
    };

    return (
        <Card>
            <CardHeader title="Total Stock Value History" />
            <CardContent>
                <div>
                    <label>Start Date</label>
                    <input type="date" value={date} onChange={(e) => setDate(e.target.value)}/>
                </div>
                <div style={{ width: '100%', height: 300 }}>
                    <ResponsiveContainer>
                        <AreaChart data={getData(inventoryValues)}>
                            <defs>
                                <linearGradient
                                    id="colorUv"
                                    x1="0"
                                    y1="0"
                                    x2="0"
                                    y2="1"
                                >
                                    <stop
                                        offset="5%"
                                        stopColor="#8884d8"
                                        stopOpacity={0.8}
                                    />
                                    <stop
                                        offset="95%"
                                        stopColor="#8884d8"
                                        stopOpacity={0}
                                    />
                                </linearGradient>
                            </defs>
                            <XAxis
                                dataKey="date"
                                name="Date"
                            />
                            <YAxis dataKey="value" name="Value" type="number" domain={['auto', 'auto']} />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip
                                cursor={{ strokeDasharray: '3 3' }}
                            />
                            <Area
                                type="monotone"
                                dataKey="value"
                                stroke="#8884d8"
                                strokeWidth={2}
                                fill="url(#colorUv)"
                            />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    );

}

export default InventoryValue;