import * as React from 'react';
import {UserMenu} from 'react-admin';
import {MenuItem} from "@mui/material";
import {AuthenticatedTemplate, UnauthenticatedTemplate, useMsal} from "@azure/msal-react";
import {forwardRef} from "react";
import ExitIcon from '@mui/icons-material/PowerSettingsNew';
import LoginIcon from '@mui/icons-material/Login';
import config from "../config";

const MyLoginButton = forwardRef((props, ref) => {
    const { instance } = useMsal();
    const handleClick = () => {
        instance.loginPopup(config.loginRequest).catch((error) => console.log(error));
    };
    return (
        <MenuItem onClick={handleClick} ref={ref}>
            <LoginIcon/> Login
        </MenuItem>
    );
});

const MyLogoutButton = forwardRef((props, ref) => {
    const { instance } = useMsal();
    const handleClick = () => {
        instance.logoutRedirect({postLogoutRedirectUri: "/"});
    };
    return (
        <MenuItem onClick={handleClick} ref={ref}>
            <ExitIcon /> Logout
        </MenuItem>
    );
});

export const MyUserMenu = props => (
    <UserMenu {...props}>
        <UnauthenticatedTemplate>
            <MyLoginButton />
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
            <MyLogoutButton />
        </AuthenticatedTemplate>
    </UserMenu>
);