import * as React from "react";
import {
    AutocompleteInput,
    Edit,
    DateInput,
    ReferenceInput,
    required,
    SimpleForm,
    TextInput,
    number,
    minValue,
    Create,
    useRecordContext,
    ArrayInput,
    SimpleFormIterator,
    TextField, ReferenceField, NumberField
} from "react-admin";
import {Box, Card, CardContent, Grid, Typography} from "@mui/material";
import {Spacer} from "../Spacer";
import {CustomNumberInput} from "../CustomNumberInput";

const validatePurchasePrice = [number(), minValue(0)];
const validatePurchaseShippingPrice = [number(), minValue(0)];
const validateImportDutiesPrice = [number(), minValue(0)];

const PurchaseForm = () => {
    const record = useRecordContext();
    const disabled = record != null && (record.invoiced || record.received);
    return (
        <SimpleForm sx={{maxWidth: 1200}} redirect="show">
            <Box minWidth="800px">
                <Card>
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={6}>
                                <Typography variant="h6" gutterBottom>Purchase</Typography>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <DateInput label="Order date" source="orderDate" validate={[required()]} defaultValue={new Date()}/>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <DateInput label="Invoice date" source="invoiceDate" />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <DateInput label="Delivery Date" source="deliveryDate"/>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextInput label="Order Number" source="orderNumber" validate={[required()]}/>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextInput label="Remark" source="remark" fullWidth multiline/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <Typography variant="h6" gutterBottom>Supplier</Typography>
                                <ReferenceInput label="Supplier" source="supplier.id" reference="relations" validate={[required()]}
                                                filter={{supplier: true, active: true}} sort={{field: 'company', order: 'ASC'}}>
                                    <AutocompleteInput optionText="company" fullWidth/>
                                </ReferenceInput>
                            </Grid>
                        </Grid>
                        <Spacer/>
                        <Typography variant="h6" gutterBottom>Items</Typography>
                        <Box>
                            <ArrayInput source="items">
                                <SimpleFormIterator inline disableReordering disableAdd={disabled} disableRemove={disabled} disableClear={disabled}>
                                    {disabled ?
                                        <ReferenceField label="Product" reference="products" source="product.id" filter={{ active: true }} validate={[required()]}/>
                                    :
                                        <ReferenceInput label="Product" reference="products" source="product.id" filter={{ active: true }} validate={[required()]}>
                                            <AutocompleteInput sx={{minWidth: 450}}/>
                                        </ReferenceInput>
                                    }
                                    <TextField label="Supplier sku" source="product.skuSupplier" />
                                    {disabled ?
                                        <NumberField source="qty" validate={[required()]}/>
                                    :
                                        <CustomNumberInput source="qty" validate={[required()]}/>
                                    }
                                    <CustomNumberInput source="purchasePrice" validate={validatePurchasePrice}/>
                                    <AutocompleteInput label="Currency" source="purchaseCurrency" choices={[
                                        {id: 'EUR', name: 'EUR'},
                                        {id: 'USD', name: 'USD'},
                                        {id: 'GBP', name: 'GBP'}
                                    ]}/>
                                    <CustomNumberInput source="purchaseShippingPrice" validate={validatePurchaseShippingPrice}/>
                                    <AutocompleteInput label="Currency" source="purchaseShippingCurrency" choices={[
                                        {id: 'EUR', name: 'EUR'},
                                        {id: 'USD', name: 'USD'},
                                        {id: 'GBP', name: 'GBP'}
                                    ]}/>
                                    <CustomNumberInput source="importDutiesPrice" validate={validateImportDutiesPrice}/>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </SimpleForm>
    )
};

export const PurchaseCreate = () => {
    return (
        <Create title="Create New Purchase" component="div" redirect="show">
            <PurchaseForm/>
        </Create>
    );
}

export const PurchaseEdit = () => {
    return (
        <Edit title={<PurchaseTitle/>} component="div" redirect="show">
            <PurchaseForm/>
        </Edit>
    );
}

const PurchaseTitle = () => {
    const record = useRecordContext();
    return <span>Purchase {record ? `"${record.supplier.company} ${record.orderNumber}"` : ''}</span>;
};