import * as React from 'react';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import CardWithIcon from './CardWithIcon';

const NbNewOrders = (props) => {
    return (
        <CardWithIcon
            to="/orders"
            icon={ShoppingCartIcon}
            title={props.title}
            subtitle={props.value}
        />
    );
};

export default NbNewOrders;