import {
    AutocompleteInput,
    BooleanInput,
    Create,
    DateField, maxLength, maxValue, minValue, number,
    ReferenceInput, required,
    SimpleForm,
    TextInput
} from "react-admin";
import * as React from "react";
import {CustomNumberInput} from "../CustomNumberInput";

const validateName = required();
const validateBrand = required();
const validateProductType = required();
const validateModel = required();
const validateNumber = [required(), number(), maxLength(3)];
const validateSellingPrice = [required(), number(), minValue(0)];
const validatePurchasePrice = [number(), minValue(0)];
const validatePurchaseShippingPrice = [number(), minValue(0)];
const validateRetailPrice = [number(), minValue(0)];
const validateDealerDiscountPercent = [number(), minValue(0), maxValue(100)];
const validateLength = [number(), minValue(0)];
const validateWidth = [number(), minValue(0)];
const validateHeight = [number(), minValue(0)];
const validateWeight = [number(), minValue(0)];

const ProductCreate = props => (
    <Create {...props} title="Create a Product">
        <SimpleForm>
            <TextInput source="skuSupplier"/>
            <TextInput source="name" validate={validateName} fullWidth/>
            <ReferenceInput label="Brand" source="brand.id" reference="brands" sort={{ field: 'name', order: 'ASC' }} validate={validateBrand} >
                <AutocompleteInput sx={{minWidth: 300}}/>
            </ReferenceInput>
            <ReferenceInput label="ProductType" source="productType.id" reference="productTypes" sort={{ field: 'name', order: 'ASC' }} validate={validateProductType} >
                <AutocompleteInput sx={{minWidth: 300}}/>
            </ReferenceInput>
            <ReferenceInput label="Model" source="model.id" reference="models" sort={{ field: 'name', order: 'ASC' }} validate={validateModel} >
                <AutocompleteInput sx={{minWidth: 300}}/>
            </ReferenceInput>
            <TextInput source="number" validate={validateNumber} />
            <TextInput source="remark" multiline fullWidth/>
            <TextInput source="url" fullWidth/>
            <ReferenceInput label="Country" source="countryOfManufacture" reference="countries" helperText={false} >
                <AutocompleteInput source="countryOfManufacture" sx={{ width: 250 }} helperText={false}/>
            </ReferenceInput>
            <TextInput source="hsCode"/>
            <CustomNumberInput source="additionalUnits"/>
            <CustomNumberInput source="sellingPrice" validate={validateSellingPrice}/>
            <BooleanInput source="onCustomerWebshop" />
            <BooleanInput source="onDealerWebshop" />
            <CustomNumberInput source="retailPrice" validate={validateRetailPrice} />
            <AutocompleteInput source="retailCurrency" choices={[
                { id: 'EUR', name: 'EUR' },
                { id: 'USD', name: 'USD' },
                { id: 'GBP', name: 'GBP' }
            ]} />
            <CustomNumberInput source="purchasePrice" validate={validatePurchasePrice} />
            <AutocompleteInput source="purchaseCurrency" choices={[
                { id: 'EUR', name: 'EUR' },
                { id: 'USD', name: 'USD' },
                { id: 'GBP', name: 'GBP' }
            ]} />
            <CustomNumberInput source="purchaseShippingPrice" validate={validatePurchaseShippingPrice} />
            <AutocompleteInput source="purchaseShippingCurrency" choices={[
                { id: 'EUR', name: 'EUR' },
                { id: 'USD', name: 'USD' },
                { id: 'GBP', name: 'GBP' }
            ]} />
            <CustomNumberInput source="dealerDiscountPercent" validate={validateDealerDiscountPercent}/>
            <CustomNumberInput source="length" validate={validateLength} helperText="Value in cm"/>
            <CustomNumberInput source="width" validate={validateWidth} helperText="Value in cm"/>
            <CustomNumberInput source="height" validate={validateHeight} helperText="Value in cm"/>
            <CustomNumberInput source="weight" validate={validateWeight} helperText="Value in kg"/>
            <BooleanInput source="featured" />
            <BooleanInput source="active" />
            <DateField source="createdAt" showTime />
            <DateField source="updatedAt" showTime />
        </SimpleForm>
    </Create>
);

export default ProductCreate;