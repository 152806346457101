import * as React from 'react';
import { Fragment, useCallback, useEffect, useState } from 'react';
import {
    DateField,
    List,
    ListContextProvider,
    NumberField,
    ReferenceField,
    SearchInput,
    TextField,
    useGetList,
    useListContext,
    ReferenceInput,
    AutocompleteInput,
    BooleanField,
    DateInput,
    TextInput,
    useDataProvider,
    useRefresh,
    useUnselectAll,
    useNotify, SelectColumnsButton, DatagridConfigurable, TopToolbar, FilterButton, ExportButton
} from 'react-admin';
import {useMediaQuery, Divider, Tabs, Tab, Button} from '@mui/material';
import {CurrencyNumberField} from "../CurrencyNumberField";
import {useMutation} from "react-query";
import MargeCurrencyField from "../MargeCurrencyField";
import config from "../../config";
import {stringify} from "query-string";
import NotificationsIcon from '@mui/icons-material/Notifications';

const orderFilters = [
    <SearchInput source="q" alwaysOn />,
    <ReferenceInput source="customer" reference="relations" filter={{ customer: true, active: true }} >
        <AutocompleteInput />
    </ReferenceInput>,
    <DateInput label="Created Since" source="createdAt_gte" />,
    <TextInput source="trackingNumber"/>,
];

const tabs = [
    { id: 'PROCESSING', name: 'PROCESSING' },
    { id: 'COMPLETE', name: 'COMPLETE' },
    { id: 'CANCELED', name: 'CANCELED' },
];

const useGetTotals = (filterValues) => {
    const { total: totalProcessing } = useGetList(
        'orders',
        {
            pagination: {perPage: 1, page: 1},
            sort: {field: 'orderNumber', order: 'ASC'},
            filter: {...filterValues, state: 'PROCESSING'}
        }
    );
    const { total: totalComplete } = useGetList(
        'orders',
        {
            pagination: {perPage: 1, page: 1},
            sort: {field: 'orderNumber', order: 'ASC'},
            filter: {...filterValues, state: 'COMPLETE'}
        }
    );
    const { total: totalCanceled } = useGetList(
        'orders',
        {
            pagination: {perPage: 1, page: 1},
            sort: {field: 'orderNumber', order: 'ASC'},
            filter: {...filterValues, state: 'CANCELED'}
        }
    );

    return {
        PROCESSING: totalProcessing,
        COMPLETE: totalComplete,
        CANCELED: totalCanceled,
    };
};

const SyncOrderButton = () => {
    const { selectedIds } = useListContext();
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll('orders');

    const dataProvider = useDataProvider();
    const { mutate, isLoading } = useMutation(
        ['orderIds', selectedIds],
        () => dataProvider.syncMagentoOrders(selectedIds),
        {
            onSuccess: () => {
                refresh();
                notify('Selected orders are synchronized with Magento.');
                unselectAll();
            },
            onError: error => notify('Error: orders not synchronized', { type: 'warning' }),
        }
    );
    return (<Button label="Sync Order" onClick={() => { mutate(); refresh(); } } disabled={isLoading} size="small">Sync Order</Button>);
};

const CreateYukiInvoiceButton = () => {
    const { selectedIds } = useListContext();
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll('orders');

    const dataProvider = useDataProvider();
    const { mutate, isLoading } = useMutation(
        ['orderIds', selectedIds],
        () => dataProvider.createYukiInvoices(selectedIds),
        {
            onSuccess: () => {
                refresh();
                notify('For selected orders are Yuki invoices created.');
                unselectAll();
            },
            onError: error => notify('Error: creating Yuki invoices', { type: 'warning' }),
        }
    );
    return (<Button label="Create Yuki Invoice" onClick={() => { mutate(); refresh(); } } disabled={isLoading} size="small">Create Yuki Invoice</Button>);
};

const CalculateMarginButton = () => {
    const { selectedIds } = useListContext();
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll('orders');

    const dataProvider = useDataProvider();
    const { mutate, isLoading } = useMutation(
        ['orderIds', selectedIds],
        () => dataProvider.calculateMargins(selectedIds),
        {
            onSuccess: () => {
                refresh();
                notify('For selected orders are margins calculated.');
                unselectAll();
            },
            onError: error => notify('Error: calculating margins', { type: 'warning' }),
        }
    );
    return (<Button label="Calculate Margin" onClick={() => { mutate(); refresh(); } } disabled={isLoading} size="small">Calculate Margin</Button>);
};

const CreateDHLShipmentFileButton = () => {
    const { selectedIds } = useListContext();
    const notify = useNotify();
    const unselectAll = useUnselectAll('orders');
    const [loading, setLoading] = useState(false);

    async function downloadFile() {
        setLoading(true);
        const query = {
            ids: selectedIds,
        };
        const response = await fetch(`${config.serverHost}/api/orders/createDHLShipmentFile?${stringify(query)}`, {
            headers: {
                "Accept": "application/csv",
                "Authorization": `Bearer ${localStorage.getItem("access_token")}`
            }
        });
        const contentDisposition = response.headers.get('Content-Disposition');
        const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim().replaceAll("\"","");

        const myBlob = await response.blob();
        const objUrl = window.URL.createObjectURL(myBlob);

        let link = document.createElement('a');
        link.href = objUrl;
        link.download = filename;
        link.click();

        setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);

        notify('For selected orders are DHL Shipment file generated.');
        setLoading(false);
        unselectAll();
    }

    return (<Button label="Generate DHL file" onClick={downloadFile} disabled={loading} size="small">Generate DHL file</Button>);
};

const PostBulkActionButtons = () => (
    <>
        <SyncOrderButton />
        <CreateYukiInvoiceButton />
        <CalculateMarginButton />
        <CreateDHLShipmentFileButton />
    </>
);

const OrderListActions = () => (
    <TopToolbar>
        <SelectColumnsButton />
        <FilterButton />
        <ExportButton />
    </TopToolbar>
);

const TabbedDatagrid = () => {
    const listContext = useListContext();
    const { ids, filterValues, setFilters, displayedFilters, isLoading } = listContext;
    const isXSmall = useMediaQuery(theme =>
        theme.breakpoints.down('xs')
    );
    const [processing, setProcessing] = useState();
    const [complete, setComplete] = useState();
    const [canceled, setCanceled] = useState();
    const totals = useGetTotals(filterValues);

    useEffect(() => {
        if (isLoading) {
            return;
        }
        if (filterValues.state) {
            switch (filterValues.state) {
                case 'PROCESSING':
                    setProcessing(ids);
                    break;
                case 'COMPLETE':
                    setComplete(ids);
                    break;
                case 'CANCELED':
                    setCanceled(ids);
                    break;
            }
        }
    }, [ids, isLoading, filterValues.state]);

    const handleChange = useCallback(
        (event: React.ChangeEvent<{}>, value) => {
            setFilters &&
            setFilters(
                { ...filterValues, state: value },
                displayedFilters,
                false // no debounce, we want the filter to fire immediately
            );
        },
        [displayedFilters, filterValues, setFilters]
    );

    const selectedIds =
        filterValues.state === 'PROCESSING'
            ? processing
            : filterValues.state === 'COMPLETE'
                ? complete
                : canceled;

    const postRowSx = (record, index) => ({
        backgroundColor: record.paid && record.inStock && !record.hasOpenCoreRefunds ? '#efe' : 'white',
    });

    return (
        <Fragment>
            <Tabs
                variant="fullWidth"
                centered
                value={filterValues.state}
                indicatorColor="primary"
                onChange={handleChange}
            >
                {tabs.map(choice => (
                    <Tab
                        key={choice.id}
                        label={
                            totals[choice.name]
                                ? `${choice.name} (${totals[choice.name]})`
                                : choice.name
                        }
                        value={choice.id}
                    />
                ))}
            </Tabs>
            <Divider />
            {isXSmall ? (
                <ListContextProvider
                    value={{ ...listContext, ids: selectedIds }}
                >
                    {/*<MobileGrid {...props} ids={selectedIds} />*/}
                </ListContextProvider>
            ) : (
                <div>
                    {filterValues.state === 'PROCESSING' && (
                        <ListContextProvider
                            value={{ ...listContext, ids: processing }}
                        >
                            <DatagridConfigurable rowClick="edit" bulkActionButtons={<PostBulkActionButtons />} rowStyle={postRowSx}>
                                <DateField source="createdAt" showTime />
                                <TextField source="orderNumber" />
                                <ReferenceField label="Customer" reference="relations" source="customer.id" link={false}/>
                                <NumberField source="totalQty" />
                                <CurrencyNumberField source="grandTotal" currency="currencyCode" sx={{ fontWeight: 'bold' }}/>
                                <MargeCurrencyField source="margin" total="grandTotalWithoutCoreDeposit" currency="currencyCode" dealerOrder="dealerOrder" />
                                <CurrencyNumberField source="realShippingAmount" currency="currencyCode" />
                                <BooleanField source="paid"/>
                                <BooleanField source="inStock"/>
                                <BooleanField source="hasOpenCoreRefunds" label="Core Ref" TrueIcon={NotificationsIcon} FalseIcon={null}/>
                                <BooleanField source="shipped"/>
                                <BooleanField source="invoiced"/>
                                <TextField source="remark" />
                            </DatagridConfigurable>
                        </ListContextProvider>
                    )}
                    {filterValues.state === 'COMPLETE' && (
                        <ListContextProvider
                            value={{ ...listContext, ids: complete }}
                        >
                            <DatagridConfigurable rowClick="edit" bulkActionButtons={<PostBulkActionButtons />}>
                                <DateField source="createdAt" showTime />
                                <TextField source="orderNumber" />
                                <ReferenceField label="Customer" reference="relations" source="customer.id" link={false}/>
                                <NumberField source="totalQty" />
                                <CurrencyNumberField source="grandTotal" currency="currencyCode" sx={{ fontWeight: 'bold' }}/>
                                <MargeCurrencyField source="margin" total="grandTotalWithoutCoreDeposit" currency="currencyCode" dealerOrder="dealerOrder" />
                                <CurrencyNumberField source="realShippingAmount" currency="currencyCode" />
                                <BooleanField source="paid"/>
                                {/*<BooleanField source="hasOpenCoreRefunds" label="Core Ref" TrueIcon={NotificationsIcon} FalseIcon={null}/>*/}
                                <BooleanField source="shipped"/>
                                <BooleanField source="invoiced"/>
                                <TextField source="remark" />
                            </DatagridConfigurable>
                        </ListContextProvider>
                    )}
                    {filterValues.state === 'CANCELED' && (
                        <ListContextProvider
                            value={{ ...listContext, ids: canceled }}
                        >
                            <DatagridConfigurable rowClick="edit" bulkActionButtons={<PostBulkActionButtons />}>
                                <DateField source="createdAt" showTime />
                                <TextField source="orderNumber" />
                                <ReferenceField label="Customer" reference="relations" source="customer.id" link={false}/>
                                <NumberField source="totalQty" />
                                <CurrencyNumberField source="grandTotal" currency="currencyCode" sx={{ fontWeight: 'bold' }}/>
                            </DatagridConfigurable>
                        </ListContextProvider>
                    )}
                </div>
            )}
        </Fragment>
    );
};

export const OrderList = (props) => (
    <List {...props}
          filterDefaultValues={{ state: 'PROCESSING' }}
          sort={{ field: 'createdAt', order: 'DESC' }}
          perPage={25}
          filters={orderFilters}
          actions={<OrderListActions/>}
    >
        <TabbedDatagrid />
    </List>
);

