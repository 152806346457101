import * as React from "react";
import {
    useRecordContext,
    ReferenceField,
    DateField,
    ArrayField,
    TextField,
    SelectField,
    NumberField,
    Datagrid,
    TopToolbar,
    ListButton,
    EditButton,
    useDataProvider,
    useRefresh,
    Labeled,
    TextInput,
    BooleanInput,
    FormTab,
    TabbedForm,
    Toolbar,
    SaveButton, Edit, BooleanField, PrevNextButtons
} from "react-admin";
import {Box, Button, Card, CardContent, Grid, Typography} from "@mui/material";
import {Spacer} from "../Spacer";
import {CurrencyNumberField} from "../CurrencyNumberField";
import {useMutation} from "react-query";
import {CustomUrlField} from "../CustomUrlField";
import CreateReceiptPanel from "./CreateReceiptPanel";

const InvoicedButton = ({ purchaseId, disabled }) => {
     const dataProvider = useDataProvider();
     const refresh = useRefresh();
     const { mutate, isLoading } = useMutation(
         ['purchaseId', purchaseId],
         () => dataProvider.invoicePurchase(purchaseId),
         {
             onSuccess: (data) => {
                 refresh();
             }
         }
     );
     return (<Button label="Invoiced" onClick={() => { mutate(); } } disabled={isLoading || disabled} size="small">Invoiced</Button>);
};

const PurchaseEditActions = () => {
    const record = useRecordContext();
    return (
        <TopToolbar>
            <PrevNextButtons linkType="show"/>
            <ListButton/>
            {record && (<EditButton />)}
            {record && (<InvoicedButton purchaseId={record.id} disabled={record.invoiced} />)}
        </TopToolbar>
    )
};

const PurchaseEditToolbar = () => {
    return (
        <Toolbar>
            <SaveButton />
        </Toolbar>
    )
};

export const PurchaseShow = () => {
    return (
        <Edit title={<PurchaseTitle/>} actions={<PurchaseEditActions/>} component="div">
            <TabbedForm toolbar={<PurchaseEditToolbar/>}>
                <FormTab label="Information">
                    <Box>
                        <Card>
                            <CardContent>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Typography variant="h6" gutterBottom>Purchase</Typography>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <Labeled label="Order Date">
                                                    <DateField source="orderDate" showTime/>
                                                </Labeled>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <Labeled label="Invoice Date">
                                                    <DateField source="invoiceDate" showTime/>
                                                </Labeled>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <Labeled label="Delivery Date">
                                                    <DateField source="deliveryDate" showTime/>
                                                </Labeled>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <Box mt={2}>
                                                    <BooleanInput row={true} source="received" disabled={true}/>
                                                    <BooleanInput row={true} source="invoiced" disabled={true}/>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <Labeled label="Order Number">
                                                    <TextInput source="orderNumber"/>
                                                </Labeled>
                                            </Grid>
                                        </Grid>
                                        <Spacer/>
                                        <TextInput source="remark" fullWidth multiline/>
                                        <Spacer/>
                                        <Labeled label="State">
                                            <SelectField source="state"
                                                         choices={[
                                                             {
                                                                 id: 'COMPLETE',
                                                                 name: 'COMPLETE',
                                                             },
                                                             {
                                                                 id: 'PROCESSING',
                                                                 name: 'PROCESSING',
                                                             },
                                                             {
                                                                 id: 'CANCELED',
                                                                 name: 'CANCELED',
                                                             }
                                                         ]}
                                            />
                                        </Labeled>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                        <Typography variant="h6" gutterBottom>Supplier</Typography>
                                        <ReferenceField label="Supplier" source="supplier.id" reference="relations"/>
                                        <Typography><TextField source="supplier.email"/></Typography>
                                        <Typography><TextField source="supplier.telephone"/></Typography>
                                        <Typography><TextField source="supplier.mobile"/></Typography>
                                    </Grid>
                                </Grid>
                                <Spacer/>
                                <Typography variant="h6" gutterBottom>Items</Typography>
                                <Box>
                                    <ArrayField source="items" sort={{ field: 'id', order: 'ASC' }}>
                                        <Datagrid bulkActionButtons={false}>
                                            <ReferenceField label="Product" reference="products" source="product.id" />
                                            <TextField label="Supplier sku" source="product.skuSupplier" />
                                            <NumberField source="qty"/>
                                            <NumberField source="qtyReceived"/>
                                            <CurrencyNumberField source="purchasePrice" currency="purchaseCurrency"/>
                                            <NumberField label="Current purchase price" source="product.purchasePrice"/>
                                            <TextField label="Currency" source="product.purchaseCurrency"/>
                                            <NumberField source="totalPurchaseShippingPrice" options={{style: 'currency', currency: 'EUR'}} />
                                            <NumberField label="Current purchase shipping price" source="product.purchaseShippingPrice"/>
                                            <TextField label="Currency" source="product.purchaseShippingCurrency"/>
                                        </Datagrid>
                                    </ArrayField>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                </FormTab>
                <FormTab label="Receipts">
                    <Box >
                        <Card>
                            <CardContent>
                                <Box>
                                    <CreateReceiptPanel/>
                                    <Spacer/>
                                    <ArrayField source="receipts" sort={{ field: 'id', order: 'ASC' }}>
                                        <Datagrid bulkActionButtons={false}>
                                            <DateField source="createdAt" showTime label="Receipt Date"/>
                                            <DateField source="updatedAt" showTime />
                                            <TextField source="carrier"/>
                                            <CustomUrlField source="trackingNumber" urlSource="url" target="_blank"/>
                                            <BooleanField source="dropShipment"/>
                                        </Datagrid>
                                    </ArrayField>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
}

const PurchaseTitle = () => {
    const record = useRecordContext();
    return <span>Purchase {record ? `"${record.supplier.company} ${record.orderNumber}"` : ''}</span>;
};