import { Layout, CheckForApplicationUpdate } from 'react-admin';
import { MyMenu } from './MyMenu';
import {MyAppBar} from "./MyAppBar";
import { ReactQueryDevtools } from 'react-query/devtools';

const MyLayout = (props) =>{
    return (
        <>
            <Layout {...props} menu={MyMenu} appBar={MyAppBar}/>
            <CheckForApplicationUpdate />
            {/*<<ReactQueryDevtools initialIsOpen={false} />*/}
        </>
    );
}

export default MyLayout;