import React, {useState} from "react";
import {Card, CardContent, CardHeader, Input, InputLabel} from "@mui/material";
import {useGetList} from "react-admin";
import {endOfYear, format, parseISO,  startOfYear} from "date-fns";
import {
    Bar,
    CartesianGrid, ComposedChart,
    Legend, Line,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";

const RevenueReport = () => {
    const [startDate, setStartDate] = useState(startOfYear(new Date()));
    const [endDate, setEndDate] = useState(endOfYear(new Date()));

    const { data: marginPerBrandPerMonths } = useGetList('marginPerBrandPerMonths', {
        filter: { date_gte: (startDate ? format(parseISO(format(startDate,'yyyy-MM-dd')), 'yyyy-MM-dd') : null), date_lte: (endDate ? format(parseISO(format(endDate,'yyyy-MM-dd')), 'yyyy-MM-dd') : null) },
        sort: { field: 'date', order: 'ASC' },
        pagination: { page: 1, perPage: 1000 },
    });

    if (!marginPerBrandPerMonths) return null;

    const allBrandSet = new Set();

    const getRevenueData = (marginPerBrandPerMonths): [] => {
        const daysWithRevenue = new Set();
        const l = marginPerBrandPerMonths.reduce((acc, curr) => {
                const day = format(new Date(curr.date), 'yyyy-MM');
                if (!acc[day]) {
                    acc[day] = {date: day, total: 0, previousYear: 0};
                }
                acc[day] = {...acc[day], previousYear: acc[day].previousYear + curr.previousYearTotalRevenue, total: acc[day].total + curr.totalRevenue, [curr.brand.groupedBrandName]: (acc[day][curr.brand.groupedBrandName] ? acc[day][curr.brand.groupedBrandName] :0) + curr.totalRevenue,};
                daysWithRevenue.add(day);
                allBrandSet.add("" + curr.brand.groupedBrandName);
                return acc;
            },{});
        const r = [];
        for (const date of daysWithRevenue) {
            r.push({date: date, ...l[date]});
        }
        return r;
    };

    const getMarginData = (marginPerBrandPerMonths): [] => {
        const daysWithMargin = new Set();
        const l = marginPerBrandPerMonths.reduce((acc, curr) => {
            const day = format(new Date(curr.date), 'yyyy-MM');
            if (!acc[day]) {
                acc[day] = {date: day, total: 0, previousYear: 0};
            }
            acc[day] = {...acc[day], previousYear: acc[day].previousYear + curr.previousYearTotalMargin, total: acc[day].total + curr.totalMargin, [curr.brand.groupedBrandName]: (acc[day][curr.brand.groupedBrandName] ? acc[day][curr.brand.groupedBrandName] : 0) + curr.totalMargin,};
            daysWithMargin.add(day);
            return acc;
        },{});
        const r = [];
        for (const date of daysWithMargin) {
            r.push({date: date, ...l[date]});
        }
        return r;
    };

    const COLORS = ['#f44336','#2196f3', '#8bc34a', '#ff5722','#795548','#cddc39','#e81e63','#9e9e9e','#ffeb3b','#00bcd4','#9c27b0','#607d8b','#ffc107','#009688','#673ab7','#ff9800','#4caf50','#3f51b5'];

    const revenueData = getRevenueData(marginPerBrandPerMonths);
    const marginData = getMarginData(marginPerBrandPerMonths);
    const allBrands = Array.from(allBrandSet)

    return (
        <Card>
            <CardHeader title="Total Revenue per Vendor report" />
            <CardContent>
                <div style={{ display: 'flex' }}>
                    <div style={{marginRight: '0.5em'}}>
                        <InputLabel shrink>Start Date</InputLabel>
                        <Input type="month" value={format(startDate,'yyyy-MM')} onChange={(e) => setStartDate(new Date(e.target.value))}/>
                    </div>
                    <div style={{marginRight: '0.5em'}}>
                        <InputLabel shrink>End Date</InputLabel>
                        <Input type="month" value={format(endDate,'yyyy-MM')} onChange={(e) => setEndDate(new Date(e.target.value))}/>
                    </div>
                </div>
                <div style={{ width: '100%', height: 600 }}>
                    <ResponsiveContainer>
                        <ComposedChart width={500}
                                  height={300}
                                  data={revenueData}
                                  margin={{
                                      top: 5,
                                      right: 30,
                                      left: 20,
                                      bottom: 5,
                                  }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip cursor={{ strokeDasharray: '3 3' }}
                                     formatter={(value: any) =>
                                         new Intl.NumberFormat(undefined, {
                                             style: 'currency',
                                             currency: 'EUR',
                                         }).format(value)
                                     }
                            />
                            <Legend />
                            {allBrands.map((brand, index) => (
                                <Bar dataKey={`${brand}`} fill={COLORS[index % COLORS.length]} stackId="a" />
                            ))}
                            <Line type="monotone" dataKey="total" fill="#000000" stroke="#000000" />
                            <Line type="monotone" dataKey="previousYear" fill="#FF1000" stroke="#FF1000" />
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
            </CardContent>
            <CardHeader title="Total Margin per Vendor report" />
            <CardContent>
                <div style={{ width: '100%', height: 600 }}>
                    <ResponsiveContainer>
                        <ComposedChart width={500}
                                  height={300}
                                  data={marginData}
                                  margin={{
                                      top: 5,
                                      right: 30,
                                      left: 20,
                                      bottom: 5,
                                  }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip cursor={{ strokeDasharray: '3 3' }}
                                     formatter={(value: any) =>
                                         new Intl.NumberFormat(undefined, {
                                             style: 'currency',
                                             currency: 'EUR',
                                         }).format(value)
                                     }
                            />
                            <Legend />
                            {allBrands.map((brand, index) => (
                                <Bar dataKey={`${brand}`} fill={COLORS[index % COLORS.length]} stackId="a" />
                            ))}
                            <Line type="monotone" dataKey="total" fill="#000000" stroke="#000000" />
                            <Line type="monotone" dataKey="previousYear" fill="#FF1000" stroke="#FF1000" />
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    );

}

export default RevenueReport;