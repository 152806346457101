import {
    Button,
    Link,
    NumberField, ReferenceField,
    SimpleShowLayout, TextField,
    useDataProvider,
    useGetList, useNotify, useRefresh
} from "react-admin";
import {Card, CardActions, CardContent, Container, Grid, Typography} from "@mui/material";
import * as React from "react";
import {useMutation} from "react-query";
import {useLocation} from "react-router";
import InventoryIcon from "@mui/icons-material/Inventory";
import CheckIcon from '@mui/icons-material/Check';

const ProductPanel = (props) => {
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();

    const { data: product } = useGetList('products', {
        filter: { sku: props.product },
    });

    const AddNewInventoryAdjustmentButton = () => {
        const location = useLocation();
        return (<Button
                variant="contained"
                component={Link}
                to={{
                    pathname: "/inventoryAdjustments/create",
                    // Here we specify the initial record for the create view
                    state: { record: { product_id: product[0].id, redirectPath: `${location.pathname}?productSku=${product[0].sku}` } },
                }}
                label="Adjust Stock"
            ><InventoryIcon/></Button>
        );
    }

    const StockCountingOkButton = () => {
        return (<Button
                variant="contained"
                onClick={handleStockCountingOK}
                label="Stock Counting OK"
            ><CheckIcon/></Button>
        );
    }

    const { mutate, isLoading } = useMutation(
        ['product', 'update'],
        () => dataProvider.stockCountingOk(product[0].id),
        {
            onSuccess: () => {
                refresh();
                notify('Product updated');
            },
            onError: error => notify('Error: product not updated', { type: 'warning' }),
        }
    );

    const handleStockCountingOK = (e) => {
        e.preventDefault();
        mutate();
    }

    return (
        <div>
            {product && product.length > 0 ?
                <Container>
                    <Card>
                        <CardContent>
                            <SimpleShowLayout record={product[0]}>
                                <TextField source="sku" label={false} />
                                <ReferenceField label={false} reference="products" source="id"/>
                                <Typography variant="h6" >Accounting Stock</Typography>
                                <Grid container spacing={2} columns={2}>
                                    <Grid item xs={1}>
                                        <Typography>Stock on Hand</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <NumberField source="accountingStock"/>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography>Commited Stock</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <NumberField source="commitedAccountingStock"/>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography>Available for Sale</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <NumberField source="availableAccountingStock"/>
                                    </Grid>
                                </Grid>
                                <br/>
                                <Typography variant="h6">Physical Stock</Typography>
                                <Grid container spacing={2} columns={2}>
                                    <Grid item xs={1}>
                                        <Typography>Stock on Hand</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <NumberField source="physicalStock"/>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography>Commited Stock</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <NumberField source="commitedPhysicalStock"/>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography>Available for Sale</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <NumberField source="availablePhysicalStock"/>
                                    </Grid>
                                </Grid>
                            </SimpleShowLayout>
                        </CardContent>
                        <CardActions>
                            <AddNewInventoryAdjustmentButton/>
                            <StockCountingOkButton/>
                        </CardActions>
                    </Card>
                </Container>
            : null }
        </div>
    );

}

 export default ProductPanel;