import {
    Edit,
    NumberInput,
    ReferenceField,
    SimpleForm,
    TextInput,
    useRecordContext
} from "react-admin";
import {CustomNumberInput} from "../CustomNumberInput";
import * as React from "react";

export const ProductCoreEdit = props => (
    <Edit title={<ProductCoreTitle/>}>
        <SimpleForm>
            <TextInput source="product.sku" InputProps={{ disabled: true }} />
            <ReferenceField source="product.id" reference="products" InputProps={{ disabled: true }}/>
            <NumberInput source="amount" InputProps={{ disabled: true }}/>
            <NumberInput source="amountSentBack" InputProps={{ disabled: true }}/>
            <CustomNumberInput source="amountCredited" />
            <NumberInput source="price" InputProps={{ disabled: true }}/>
            <TextInput source="priceCurrency" InputProps={{ disabled: true }}/>
        </SimpleForm>
    </Edit>
);

const ProductCoreTitle = () => {
    const record = useRecordContext();
    return <span>Cores for product {record ? `"${record.product.name}"` : ''}</span>;
};