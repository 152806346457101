import * as React from "react";
import {useRecordContext, WrapperField} from "react-admin";

export const PercentNumberField = ({ source, sx }) => {
    const record = useRecordContext();
    const percent = record[source];
    return record ? (
        <WrapperField label={source} sortBy={source} sx={sx}>
            <span>{percent} %</span>
        </WrapperField>
    ) : null;
}

PercentNumberField.defaultProps = {
    textAlign: 'right',
};