import {useWatch} from "react-hook-form";
import {
    AutocompleteInput,
    Button,
    ReferenceInput,
    required,
    SelectInput,
    TextInput,
    useGetOne,
    useRecordContext
} from "react-admin";
import * as React from "react";
import {useState} from "react";
import {Card, CardContent, Grid} from "@mui/material";
import Box from "@mui/material/Box";

const AddressInput = ({source, otherAddressAllowed, type}) => {
    const record = useRecordContext();
    const customer = useWatch({ name: 'customer' });
    const [open, setOpen] = useState(false);
    const { data: relation } = useGetOne('relations', { id: (customer instanceof Object ? customer.id : customer) });
    if (!relation) return null;

    const choices = relation.addresses
        ? relation.addresses.filter(address => address.type === type)
        : [];

    function onClickCustomAddressButton() {
        setOpen(prev => !prev);
        if (record && record.shippingAddress) {
            record.shippingAddress.customerAddressId = null;
        }
    }

    return (
        <>
            <SelectInput
                source={source}
                choices={choices}
                optionText={(choice) =>
                    choice?.id // the empty choice is { id: '' }
                        ? `${choice.type} - ${choice.firstName} ${choice.lastName} ${choice.street1} ${choice.postcode} ${choice.city} ${choice.country}`
                        : ''
                }
                label="Address"
                fullWidth
            />
            {otherAddressAllowed && <Button label="Custom Address" onClick={onClickCustomAddressButton} />}
            {otherAddressAllowed && (open || (record && record.shippingAddress && record.shippingAddress.customerAddressId == null)) &&
                <Box maxWidth="40em" minWidth="400px">
                    <Card>
                        <CardContent>
                            <Grid container spacing={0} columns={2}>
                                <Grid item xs={1}>
                                    <TextInput source="shippingAddress.firstName" validate={[required()]} helperText={false} label="First Name"/>
                                </Grid>
                                <Grid item xs={1}>
                                    <TextInput source="shippingAddress.lastName" validate={[required()]}  helperText={false} label="Last Name"/>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextInput source="shippingAddress.company" helperText={false} label="Company"/>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextInput source="shippingAddress.telephone" helperText={false} label="Telephone"/>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextInput source="shippingAddress.email" helperText={false} label="E-mail"/>
                                </Grid>
                                <Grid item xs={2}>
                                    <TextInput source="shippingAddress.street1" validate={[required()]} helperText={false} label="Street 1"/>
                                </Grid>
                                <Grid item xs={1}>
                                    <TextInput source="shippingAddress.street2" helperText={false} label="Street 2"/>
                                </Grid>
                                <Grid item xs={1}>
                                    <TextInput source="shippingAddress.street3" helperText={false} label="Street 3"/>
                                </Grid>
                                <Grid item xs={1}>
                                    <TextInput source="shippingAddress.postcode" validate={[required()]} helperText={false} label="Post code"/>
                                </Grid>
                                <Grid item xs={1}>
                                    <TextInput source="shippingAddress.city" validate={[required()]} helperText={false} label="City"/>
                                </Grid>
                                <Grid item xs={1}>
                                    <TextInput source="shippingAddress.region" helperText={false} label="Region"/>
                                </Grid>
                                <Grid item xs={1}>
                                    <ReferenceInput source="shippingAddress.country" reference="countries" helperText={false} label="Country">
                                        <AutocompleteInput source="shippingAddress.country" validate={[required()]} sx={{ width: 250 }} helperText={false}/>
                                    </ReferenceInput>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>
            }
        </>
    );
};

export default AddressInput;