import { LogLevel } from "@azure/msal-browser";

const getHost = () => {
    if (window && window.location.href) {
        const host = window.location.hostname;
        if (host) {
            return host;
        }
    }
    return null;
};

const getEnvironment = () => {
    if (getHost().includes("localhost")) {
        return "test";
    } else {
        return "prod";
    }
};

const getServerHost = () => {
    if (getEnvironment() === 'prod') {
        return "https://stock-app.azurewebsites.net"
    } else {
        return "http://localhost:8080";
    }
};

export default {
    application: "react-gui",
    applicationType: 'react',
    environment: getEnvironment(),
    serverHost: getServerHost(),
    /**
     * Configuration object to be passed to MSAL instance on creation.
     * For a full list of MSAL.js configuration parameters, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
     */
    msalConfig: {
        auth: {
            clientId: "01a85db4-4d66-4636-b088-445cd60a2ab4",
            authority: "https://login.microsoftonline.com/3043ff71-3d43-4b42-af92-1e46e788ea83", //pureturboseu.onmicrosoft.com
            redirectUri: "/", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
            postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
            navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
        },
        cache: {
            cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
            storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        },
        system: {
            loggerOptions: {
                loggerCallback: (level, message, containsPii) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case LogLevel.Error:
                            console.error(message);
                            return;
                        case LogLevel.Info:
                            console.info(message);
                            return;
                        case LogLevel.Verbose:
                            console.debug(message);
                            return;
                        case LogLevel.Warning:
                            console.warn(message);
                            return;
                    }
                }
            }
        }
    },
    /**
     * Scopes you add here will be prompted for user consent during sign-in.
     * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
     * For more information about OIDC scopes, visit:
     * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
     */
    loginRequest: {
        scopes: []
    },

    /**
     * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
     */
    protectedResources : {
        graphMe: {
            endpoint: "https://graph.microsoft.com/v1.0/me",
            scopes: ["User.Read"],
        },
        apiStock: {
            scopes: ["api://f7bb9128-068a-42c2-b52d-53bd8207b0e1/stockapp"],
        },
    },

    rolesPermissionMap: {
       "8c24aefc-4893-48f4-baee-8498a08c271b": "admin",
       "48bb4dd2-6b94-45f3-b3c2-2ea5dc3fef09": "user",
     },
};
