import * as React from 'react';
import {useMediaQuery} from '@mui/material';
import {
    ReferenceInput,
    SearchInput,
    List,
    SimpleList,
    TextField,
    BooleanField,
    NumberField,
    BooleanInput,
    AutocompleteInput,
    CloneButton,
    TopToolbar,
    SelectColumnsButton,
    FilterButton,
    CreateButton,
    ExportButton,
    DatagridConfigurable,
} from 'react-admin';
import {CurrencyNumberField} from "../CurrencyNumberField";
import CustomPriceUpdateButton from "./CustomPriceUpdateButton";
import MargeNumberField from "./MargeNumberField";

const productFilters = [
    <SearchInput source="q" alwaysOn />,
    <ReferenceInput source="brand" label="Brand" reference="brands" sort={{ field: 'name', order: 'ASC' }} alwaysOn>
        <AutocompleteInput optionText="name"/>
    </ReferenceInput>,
    <ReferenceInput source="model" label="Model" reference="models" sort={{ field: 'name', order: 'ASC' }} alwaysOn>
        <AutocompleteInput optionText="name"/>
    </ReferenceInput>,
    <BooleanInput source="active" alwaysOn />
];

const PostBulkActionButtons = () => (
    <>
        <CustomPriceUpdateButton />
        {/* default bulk delete action */}
        {/*<BulkDeleteButton />*/}
    </>
);

const ProductListActions = () => (
    <TopToolbar>
        <SelectColumnsButton />
        <FilterButton />
        <CreateButton />
        <ExportButton />
    </TopToolbar>
);

const FieldWrapper = ({ children, label }) => children;

const ProductList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List {...props} filters={productFilters} sort={{ field: 'sku', order: 'ASC' }} perPage={20} actions={<ProductListActions/>}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.sku}
                    secondaryText={record => record.name}
                    tertiaryText={record => record.sellingPrice}
                />
            ) : (
                <DatagridConfigurable rowClick="edit" bulkActionButtons={<PostBulkActionButtons/>}>
                    <TextField source="sku"/>
                    <TextField source="skuSupplier"/>
                    <TextField source="name"/>
                    {/*<ReferenceField label="Brand" source="brand.id" reference="brands">
                        <TextField source="name" />
                    </ReferenceField>*/}
                    {/*<ReferenceField label="ProductType" source="productType.id" reference="productTypes">
                        <TextField source="name" />
                    </ReferenceField>*/}
                    {/*<ReferenceField label="Model" source="model.id" reference="models">
                        <TextField source="name" />
                    </ReferenceField>*/}
                    {/*<TextField source="number" />*/}
                    {/*<TextField source="productTypeWebshop" />*/}
                    <BooleanField source="onCustomerWebshop" />
                    <BooleanField source="onDealerWebshop" />
                    <NumberField source="sellingPrice" options={{ style: 'currency', currency: 'EUR' }} />
                    <NumberField source="dealerSellingPrice" options={{ style: 'currency', currency: 'EUR' }} sortable={false}/>
                    {/*<CurrencyNumberField source="purchasePrice" currency="purchaseCurrency"/>*/}
                    {/*<CurrencyNumberField source="purchaseShippingPrice" currency="purchaseShippingCurrency"/>*/}
                    <CurrencyNumberField source="retailPrice" currency="retailCurrency"/>
                    <NumberField source="totalPurchasePrice" options={{ style: 'currency', currency: 'EUR' }} sortable={false} />
                    <MargeNumberField source="margin" sortable={false} dealerField={false}/>
                    <MargeNumberField source="dealerMargin" sortable={false} dealerField={true}/>
                    {/*<NumberField source="length" options={{ maximumFractionDigits: 2 }} />*/}
                    {/*<NumberField source="width" options={{ maximumFractionDigits: 2 }} />*/}
                    {/*<NumberField source="height" options={{ maximumFractionDigits: 2 }} />*/}
                    {/*<NumberField source="weight" options={{ maximumFractionDigits: 2 }} />*/}
                    {/*<BooleanField source="featured" />*/}
                    <NumberField source="accountingStock" />
                    <NumberField source="physicalStock" />
                    <BooleanField source="active" />
                    {/* <DateField source="createdAt" showTime/>*/}
                    {/*<DateField source="updatedAt" showTime/>*/}
                    <FieldWrapper label="Actions">
                        <CloneButton />
                    </FieldWrapper>
                </DatagridConfigurable>
            )}
        </List>
    );
};

export default ProductList;
