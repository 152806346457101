import * as React from "react";
import {useRecordContext} from "react-admin";
import {Link, Typography} from "@mui/material";

export const CustomUrlField = ({ source, urlSource, target }) => {
    const record = useRecordContext();
    const url = record[urlSource];
    const urlName = record[source];

    if (url == null || url.length === 0) {
        return (
            <Typography
                component="span"
                variant="body2"
            >
                {urlName}
            </Typography>
        );
    }

    return (
        <Link
            href={url}
            variant="body2"
            target={target}
        >
            {urlName}
        </Link>
    );
}