import * as React from "react";
import {
    DateField,
    NumberField,
    ReferenceField,
    Edit,
    TextField,
    Labeled,
    useRecordContext,
    SelectField,
    ArrayField,
    Datagrid,
    BooleanInput,
    TopToolbar,
    Toolbar,
    ListButton,
    useDataProvider,
    ChipField,
    SingleFieldList,
    useRefresh,
    TabbedForm,
    FormTab,
    SaveButton,
    TextInput,
    BooleanField,
    SelectInput,
    useRedirect,
    PrevNextButtons,
    CreateButton,
    useNotify,
    Pagination,
    ReferenceManyField, useGetManyReference, Link, Button,
} from "react-admin";
import {Box, Card, CardContent, Grid, Typography} from "@mui/material";
import {useMutation, useQuery} from "react-query";
import CreateShipmentPanel from "./CreateShipmentPanel";
import {CustomUrlField} from "../CustomUrlField";
import {Spacer} from "../Spacer";
import OrderCommentPanel from "./OrderCommentPanel";
import {PercentNumberField} from "../PercentNumberField";
import {TextArrayField} from "../TextArrayField";
import CustomProductField from "../CustomProductField";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ConstructionIcon from '@mui/icons-material/Construction';
import {useState} from "react";
import config from "../../config";
import {CustomNumberInput} from "../CustomNumberInput";

const OrderTitle = () => {
    const record = useRecordContext();
    return record ? (<span>Order {record.orderNumber}</span>) : null;
};

export const ShippingAddress = () => {
    const record = useRecordContext();
    if (record.noShipping) {
        return (<Box sx={{color: 'red'}}>Collection in the shop / Provide own shipping label</Box>);
    } else if (record.shippingAddress) {
        return (<Box>
            <Typography>{record.shippingAddress.firstName} {record.shippingAddress.lastName}</Typography>
            <Typography>{record.shippingAddress.company}</Typography>
            <Typography>{record.shippingAddress.street1}</Typography>
            <Typography>{record.shippingAddress.street2}</Typography>
            <Typography>{record.shippingAddress.street3}</Typography>
            <Typography>{record.shippingAddress.postcode} {record.shippingAddress.city}</Typography>
            <Typography>{record.shippingAddress.region}</Typography>
            <Typography><ReferenceField source="shippingAddress.country" reference="countries" /></Typography>
            <Typography>{record.shippingAddress.telephone}</Typography>
            <Typography>{record.shippingAddress.email}</Typography>
        </Box>);
    }
    return (<Box></Box>);
};

export const BillingAddress = () => {
    const record = useRecordContext();
    if (record.billingAddress) {
        return (<Box>
            <Typography>{record.billingAddress.firstName} {record.billingAddress.lastName}</Typography>
            <Typography>{record.billingAddress.company}</Typography>
            <Typography>{record.billingAddress.street1}</Typography>
            <Typography>{record.billingAddress.street2}</Typography>
            <Typography>{record.billingAddress.street3}</Typography>
            <Typography>{record.billingAddress.postcode} {record.billingAddress.city}</Typography>
            <Typography>{record.billingAddress.region}</Typography>
            <Typography><ReferenceField source="billingAddress.country" reference="countries" /></Typography>
            <Typography>{record.billingAddress.telephone}</Typography>
        </Box>);
    }
    return (<Box></Box>);
};

const CreateYukiInvoiceButton = ({ orderId, enabled }) => {
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const { mutate, isLoading } = useMutation(
        ['orderId', orderId],
        () => dataProvider.createYukiInvoices(orderId),
        {
            onSuccess: (data) => {
                refresh();
            }
        }
    );
    return (<Button onClick={() => { mutate(); }} disabled={isLoading || !enabled} size="small">Create Yuki Invoice</Button>);
};

const CalculateMarginButton = ({ orderId }) => {
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const { mutate, isLoading } = useMutation(
        ['orderId', orderId],
        () => dataProvider.calculateMargins(orderId),
        {
            onSuccess: (data) => {
                refresh();
            }
        }
    );
    return (<Button onClick={() => { mutate(); } } disabled={isLoading} size="small">Calculate Margin</Button>);
};

const PaidButton = ({ orderId, enabled  }) => {
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const { mutate, isLoading } = useMutation(
        ['orderId', orderId],
        () => dataProvider.setPaid(orderId),
        {
            onSuccess: (data) => {
                refresh();
            }
        }
    );
    return (<Button onClick={() => { mutate(); } } disabled={isLoading || !enabled} size="small">Paid</Button>);
};

const SyncOrderButton = ({ orderId  }) => {
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const { mutate, isLoading } = useMutation(
        ['orderId', orderId],
        () => dataProvider.syncMagentoOrders(orderId),
        {
            onSuccess: (data) => {
                refresh();
            }
        }
    );
    return (<Button onClick={() => { mutate(); } } disabled={isLoading} size="small">Sync Order</Button>);
};

const CancelButton = ({ orderId, enabled }) => {
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const { mutate, isLoading } = useMutation(
        ['orderId', orderId],
        () => dataProvider.cancelOrder(orderId),
        {
            onSuccess: (data) => {
                refresh();
            }
        }
    );
    return (<Button onClick={() => { mutate(); } } disabled={isLoading || !enabled} size="small">Cancel</Button>);
};

const RevertToQuoteButton = ({ orderId, enabled }) => {
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const { mutate, isLoading } = useMutation(
        ['orderId', orderId],
        () => dataProvider.revertToQuote(orderId),
        {
            onSuccess: (data) => {
                refresh();
                redirect('edit', 'quotes', orderId);
            }
        }
    );
    return (<Button onClick={() => { mutate(); }} disabled={isLoading || !enabled} size="small">Revert To Quote</Button>);
};

const CloneQuoteButton = ({ order }) => {
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const { mutate, isLoading } = useMutation(
        ['order', order],
        () => dataProvider.cloneQuote(order),
        {
            onSuccess: (data) => {
                refresh();
                redirect('edit', 'quotes', data.data.id);
            }
        }
    );
    return (<Button onClick={() => { mutate(); }} disabled={isLoading} size="small">Clone Quote</Button>);
};

const GenerateQuotePdfButton = ({ orderId, enabled }) => {
    const notify = useNotify();
    const [loading, setLoading] = useState(false);

    async function downloadFile() {
        setLoading(true);
        const response = await fetch(`${config.serverHost}/api/quotes/generateQuotePdf/${orderId}`, {
            headers: {
                "Accept": "application/pdf",
                "Authorization": `Bearer ${localStorage.getItem("access_token")}`
            }
        });
        const contentDisposition = response.headers.get('Content-Disposition');
        const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim().replaceAll("\"","");

        const myBlob = await response.blob();
        const objUrl = window.URL.createObjectURL(myBlob);

        let link = document.createElement('a');
        link.href = objUrl;
        link.download = filename;
        link.click();

        setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);

        notify('Download File');
        setLoading(false);
    }
    return (<Button onClick={downloadFile} disabled={loading} size="small">Generate PDF</Button>);
};

const GeneratePackingSlipPdfButton = ({ orderId, enabled }) => {
    const notify = useNotify();
    const [loading, setLoading] = useState(false);

    async function downloadFile() {
        setLoading(true);
        const response = await fetch(`${config.serverHost}/api/orders/generatePackingSlipPdf/${orderId}`, {
            headers: {
                "Accept": "application/pdf",
                "Authorization": `Bearer ${localStorage.getItem("access_token")}`
            }
        });
        const contentDisposition = response.headers.get('Content-Disposition');
        const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim().replaceAll("\"","");

        const myBlob = await response.blob();
        const objUrl = window.URL.createObjectURL(myBlob);

        let link = document.createElement('a');
        link.href = objUrl;
        link.download = filename;
        link.click();

        setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);

        notify('Download File');
        setLoading(false);
    }
    return (<Button onClick={downloadFile} disabled={loading} size="small">Generate Packing Slip</Button>);
};

const OrderEditActions = () => {
    const record = useRecordContext();
    return (
        <TopToolbar>
            <PrevNextButtons/>
            <ListButton/>
            {record && (
                <PaidButton orderId={record.id} enabled={!record.paid}/>
            )}
            {record && (
                <CreateYukiInvoiceButton orderId={record.id} enabled={!record.invoiced}/>
            )}
            {record && (
                <CalculateMarginButton orderId={record.id}/>
            )}
            {record && !record.manualOrder && (
                <SyncOrderButton orderId={record.id}/>
            )}
            {record && (
                <CancelButton orderId={record.id} enabled={!record.paid && !record.shipped && !record.invoiced}/>
            )}
            {record && record.manualOrder && (
                <RevertToQuoteButton orderId={record.id} enabled={record.manualOrder && !record.shipped && !record.invoiced}/>
            )}
            {record && (
                <GenerateQuotePdfButton orderId={record.id}/>
            )}
            {record && (
                <CloneQuoteButton order={record}/>
            )}
            {record && !record.shipped && (
                <GeneratePackingSlipPdfButton orderId={record.id}/>
            )}
        </TopToolbar>
    )
};

const OrderEditToolbar = () => {
    return (
        <Toolbar>
            <SaveButton />
        </Toolbar>
    )
};

const PaymentMethod = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const { data, isLoadingPaymentMethods, error } = useQuery(
        ['paymentMethods', 'getPaymentMethods'],
        () => dataProvider.getPaymentMethods()
    );

    if (isLoadingPaymentMethods) return <p>LOADING</p>;
    if (!data) return null;

    const choices = data.data.map(value => ({ id: value, name: value }));
    if (record.manualOrder) {
        return (
            <SelectInput source="paymentMethod" choices={choices} helperText={false}/>
        );
    } else {
        return (
            <SelectField source="paymentMethod" choices={choices}/>
        );
    }
}

const ShipmentPanel = () => {
    const record = useRecordContext();
    return (
        <CardContent>
            <Box>
                <CreateShipmentPanel/>
                <Spacer/>
                {!record.projectOrder && <ArrayField source="shipments" sort={{ field: 'id', order: 'ASC' }}>
                    <Datagrid bulkActionButtons={false}>
                        <DateField source="createdAt" showTime label="Ship Date"/>
                        <DateField source="updatedAt" showTime />
                        <TextField source="carrier"/>
                        <CustomUrlField source="trackingNumber" urlSource="url" target="_blank"/>
                        <BooleanField source="dropShipment"/>
                    </Datagrid>
                </ArrayField>}
            </Box>
            <Spacer/>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography>Real Shipping Cost</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <CustomNumberInput source="realShippingAmount" options={{style: 'currency', currency: 'EUR'}}/>
                    </Grid>
                </Grid>
            </Box>
        </CardContent>
    );
}

/*const OrderItemsBulkActionButtons = () => (
    <>
        <CreatePurchaseButton />
    </>
);*/

/*const getSelectedItems = (data, selectedIds) => {
    const selectedRecords = data.filter(i => selectedIds.some(id => i.id === id));
    if (selectedRecords != null && selectedRecords.length > 0) {
        return selectedRecords.map(item => {
            return {product: item.product, qty: item.qty};
        });
    }
    return null;
}*/


/*const CreatePurchaseButton = () => {
    const { data, selectedIds } = useListContext();
    return (
        <CreateButton
            label=""
            icon={<ShoppingCartIcon/>}
            resource="purchases"
            state={{record: {items: getSelectedItems(data,selectedIds)}}}
        />
    );
};*/

const CreatePurchaseButton = () => {
    const record = useRecordContext();
    return (
        <CreateButton
            label=""
            icon={<ShoppingCartIcon/>}
            resource="purchases"
            state={{ record: {
                    supplier: record.product.supplier,
                    remark: "For order " + record.order.orderNumber + " (" + (record.order.customer.company ? record.order.customer.company : record.order.customer.lastName + ", " + record.order.customer.firstName) + ")",
                    items: [{product: record.product, qty: record.qty}] } }}
        />
    );
};

const AddNewServiceButton = ({}) => {
    const record = useRecordContext();
    return (<Button
            component={Link}
            to={{
                pathname: "/services/create",
                // Here we specify the initial record for the create view
                state: {
                    record: {
                        order: {id: record.order.id},
                        customer: {id: record.order.customer.id},
                        product: {id: record.product.id},
                        retourSupplier: record.product.supplier,
                        toCredit: (record.coreDeposit != null && record.coreDeposit > 0),
                    }
                },
            }}
        ><ConstructionIcon/></Button>
    );
}

const ServicesFormTab = props => {
    const record = useRecordContext();
    const { isLoading, total } = useGetManyReference(
        'services',
        {
            target: 'order',
            id: record.id,
            pagination: { page: 1, perPage: 25 },
            sort: { field: 'id', order: 'DESC' },
        },
        { enabled: !!record }
    );
    let label = 'Services ';
    if (!isLoading) {
        label += ` (${total})`;
    }
    return <TabbedForm.Tab label={label} {...props} />;
};

export const OrderEdit = () => {
    return (
        <Edit title={<OrderTitle/>} actions={<OrderEditActions/>} component="div" redirect="edit">
            <TabbedForm toolbar={<OrderEditToolbar/>} warnWhenUnsavedChanges>
                <FormTab label="Information">
                    <Box>
                        <Card>
                            <CardContent>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Typography variant="h6" gutterBottom>Order</Typography>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <Labeled label="Order Date">
                                                    <DateField source="createdAt" showTime/>
                                                </Labeled>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <Labeled label="Invoice Date">
                                                    <DateField source="invoiceDate"/>
                                                </Labeled>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <Labeled label="State">
                                                    <SelectField source="state"
                                                                 choices={[
                                                                     {
                                                                         id: 'COMPLETE',
                                                                         name: 'COMPLETE',
                                                                     },
                                                                     {
                                                                         id: 'PROCESSING',
                                                                         name: 'PROCESSING',
                                                                     },
                                                                     {
                                                                         id: 'CANCELED',
                                                                         name: 'CANCELED',
                                                                     },
                                                                     {
                                                                         id: 'unknown',
                                                                         name: 'unknown',
                                                                         disabled: true,
                                                                     },
                                                                 ]}
                                                    />
                                                </Labeled>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <Box mt={2}>
                                                    <BooleanInput row={true} source="paid" disabled/>
                                                    <BooleanInput row={true} source="inStock" disabled/>
                                                    <BooleanInput row={true} source="shipped"/>
                                                    <BooleanInput row={true} source="invoiced"/>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <Labeled label="Order Number">
                                                    <TextField source="orderNumber"/>
                                                </Labeled>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <Labeled label="Reference Number">
                                                    <TextField source="referenceNumber"/>
                                                </Labeled>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <Labeled label="Project Order" >
                                                    <BooleanField source="projectOrder" />
                                                </Labeled>
                                            </Grid>
                                        </Grid>
                                        <Spacer/>
                                        <TextInput source="remark" fullWidth multiline/>
                                        <Spacer/>
                                        <Typography variant="h6" gutterBottom>Customer</Typography>
                                        <ReferenceField label="Customer" reference="relations" source="customer.id"/>
                                        <Typography><TextField source="customer.vatNumber"/></Typography>
                                        <Typography><TextField source="customer.email"/></Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                        <Typography variant="h6" gutterBottom>Shipping Address</Typography>
                                        <ShippingAddress source="shippingAddress"/>
                                        <Spacer/>
                                        <Typography variant="h6" gutterBottom>Billing Address</Typography>
                                        <BillingAddress source="billingAddress"/>
                                        <Spacer/>
                                        <Typography variant="h6" gutterBottom>Payment</Typography>
                                        <PaymentMethod/>
                                    </Grid>
                                </Grid>
                                <Spacer/>
                                <Typography variant="h6" gutterBottom>Items</Typography>
                                <Box>
                                    <ArrayField source="items" sort={{ field: 'id', order: 'ASC' }}>
                                        <Datagrid bulkActionButtons={false}>
                                            <CustomProductField source="product.id"/>
                                            <NumberField source="originalPrice"
                                                         options={{style: 'currency', currency: 'EUR'}}/>
                                            <NumberField source="price" options={{style: 'currency', currency: 'EUR'}}/>
                                            <NumberField source="qty"/>
                                            <NumberField source="qtyShipped"/>
                                            <NumberField source="taxAmount"
                                                         options={{style: 'currency', currency: 'EUR'}}/>
                                            <PercentNumberField source="taxPercent"/>
                                            <NumberField source="discountAmount"
                                                         options={{style: 'currency', currency: 'EUR'}}/>
                                            <PercentNumberField source="discountPercent"/>
                                            <NumberField source="rowTotalInclTax"
                                                         options={{style: 'currency', currency: 'EUR'}}/>
                                            <TextArrayField source="productOptions">
                                                <SingleFieldList>
                                                    <ChipField source="id"/>
                                                </SingleFieldList>
                                            </TextArrayField>
                                            <NumberField source="coreDeposit"
                                                         options={{style: 'currency', currency: 'EUR'}}/>
                                            <CreatePurchaseButton/>
                                            <AddNewServiceButton/>
                                        </Datagrid>
                                    </ArrayField>
                                </Box>
                                <Spacer/>
                                <Typography variant="h6" gutterBottom>Totals</Typography>
                                <Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Typography>Subtotal</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <NumberField source="subtotal" options={{style: 'currency', currency: 'EUR'}}/>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography>Discount</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <NumberField source="discountAmount"
                                                         options={{style: 'currency', currency: 'EUR'}}/>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography>Payment Fee</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <NumberField source="paymentFeeAmount"
                                                         options={{style: 'currency', currency: 'EUR'}}/>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography>Shipping</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <NumberField source="shippingAmount"
                                                         options={{style: 'currency', currency: 'EUR'}}/>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography>Tax</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <NumberField source="taxAmount" options={{style: 'currency', currency: 'EUR'}}/>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography>Total</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <NumberField source="grandTotal" options={{style: 'currency', currency: 'EUR'}} sx={{ fontWeight: 'bold' }}/>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                </FormTab>
                <FormTab label="Margin">
                    <Box>
                        <Card>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>Items</Typography>
                                <Box>
                                    <ArrayField source="items" sort={{ field: 'id', order: 'ASC' }}>
                                        <Datagrid bulkActionButtons={false}>
                                            <ReferenceField label="Product" reference="products" source="product.id"/>
                                            <NumberField source="originalPrice" options={{style: 'currency', currency: 'EUR'}}/>
                                            <NumberField source="price" options={{style: 'currency', currency: 'EUR'}}/>
                                            <NumberField source="qty"/>
                                            <NumberField source="discountAmount"
                                                         options={{style: 'currency', currency: 'EUR'}}/>
                                            <NumberField source="margin"
                                                         options={{style: 'currency', currency: 'EUR'}}/>
                                        </Datagrid>
                                    </ArrayField>
                                </Box>
                                <Spacer/>
                                <Typography variant="h6" gutterBottom>Margin</Typography>
                                <Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Typography>Real Shipping</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <NumberField source="realShippingAmount" options={{style: 'currency', currency: 'EUR'}}/>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography>Margin</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <NumberField source="margin" options={{style: 'currency', currency: 'EUR'}} sx={{ fontWeight: 'bold' }}/>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                </FormTab>
                <ServicesFormTab>
                    <Box>
                        <Card>
                            <ReferenceManyField reference="services" target="order" sort={{field: "id", order: "DESC"}}
                                                perPage={10} pagination={<Pagination/>}>
                                <Datagrid rowClick="edit" bulkActionButtons={false}>
                                    <TextField source="serviceNumber"/>
                                    <ChipField source="serviceType"/>
                                    <ReferenceField label="Product" reference="products" source="product.id"
                                                    link={false}/>
                                    <DateField source="returnDate"/>
                                    <ReferenceField label="Retour Supplier" reference="relations"
                                                    source="retourSupplier.id" link={false}/>
                                    <DateField source="retourDate"/>
                                    <ChipField source="state"/>
                                    <BooleanField source="returnApproved"/>
                                    <BooleanField source="credited"/>
                                    <TextField source="remark"/>
                                </Datagrid>
                            </ReferenceManyField>
                        </Card>
                    </Box>
                </ServicesFormTab>
                <FormTab label="Shipments">
                    <Box >
                        <Card>
                            <ShipmentPanel/>
                        </Card>
                    </Box>
                </FormTab>
                <FormTab label="Comments">
                    <OrderCommentPanel/>
                </FormTab>
            </TabbedForm>
        </Edit>);
};
