import * as React from 'react';
import {Button, useMediaQuery} from '@mui/material';
import {
    ReferenceInput,
    SearchInput,
    List,
    SimpleList,
    TextField,
    DatagridConfigurable,
    ChipField,
    ReferenceField,
    DateField,
    SelectInput,
    BooleanField,
    useListContext,
    useNotify,
    useUnselectAll,
} from 'react-admin';
import {useState} from "react";
import config from "../../config";
import {stringify} from "query-string";

const CreateDHLShipmentFileButton = ({label, type}) => {
    const { selectedIds } = useListContext();
    const notify = useNotify();
    const unselectAll = useUnselectAll('services');
    const [loading, setLoading] = useState(false);

    async function downloadFile() {
        setLoading(true);
        const query = {
            ids: selectedIds,
            type: type,
        };
        const response = await fetch(`${config.serverHost}/api/services/createDHLShipmentFile?${stringify(query)}`, {
            headers: {
                "Accept": "application/csv",
                "Authorization": `Bearer ${localStorage.getItem("access_token")}`
            }
        });
        const contentDisposition = response.headers.get('Content-Disposition');
        const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim().replaceAll("\"","");

        const myBlob = await response.blob();
        const objUrl = window.URL.createObjectURL(myBlob);

        let link = document.createElement('a');
        link.href = objUrl;
        link.download = filename;
        link.click();

        setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);

        notify('For selected services are DHL Shipment file generated.');
        setLoading(false);
        unselectAll();
    }

    return (<Button label={label} onClick={downloadFile} disabled={loading} size="small">{label}</Button>);
};

const PostBulkActionButtons = () => (
    <>
        <CreateDHLShipmentFileButton label="Generate DHL file to customer" type="CUSTOMER"/>
        <CreateDHLShipmentFileButton label="Generate DHL file to supplier" type="SUPPLIER"/>
    </>
);


const serviceFilters = [
    <SearchInput source="q" alwaysOn />,
    <ReferenceInput source="customer" reference="relations" filter={{ customer: true, active: true }} sort={{field: 'lastName', order: 'ASC'}} />,
    <SelectInput source="state"
                 choices={[
                     {
                         id: 'OPEN',
                         name: 'OPEN',
                     },
                     {
                         id: 'PROCESSING',
                         name: 'PROCESSING',
                     },
                     {
                         id: 'COMPLETE',
                         name: 'COMPLETE',
                     },
                     {
                         id: 'CANCELED',
                         name: 'CANCELED',
                     },
                 ]}
    />,
    <SelectInput source="serviceType"
                 choices={[
                     {
                         id: 'CORE_REFUND',
                         name: 'CORE_REFUND',
                     },
                     {
                         id: 'REPAIR',
                         name: 'REPAIR',
                     },
                     {
                         id: 'RETURN',
                         name: 'RETURN',
                     }
                 ]}
    />,
];

const ServiceList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List {...props} filters={serviceFilters} sort={{ field: 'serviceNumber', order: 'DESC' }} perPage={20} >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.serviceType}
                    secondaryText={record => record.serviceNumber}
                    tertiaryText={record => record.state}
                />
            ) : (
                <DatagridConfigurable rowClick="edit" bulkActionButtons={<PostBulkActionButtons />} >
                    <TextField source="serviceNumber"/>
                    <ChipField source="serviceType"/>
                    <ReferenceField label="Customer" reference="relations" source="customer.id" link={false}/>
                    <ReferenceField label="Order" reference="orders" source="order.id" link={false}/>
                    <ReferenceField label="Product" reference="products" source="product.id" link={false}/>
                    <DateField source="returnDate"/>
                    <ReferenceField label="Retour Supplier" reference="relations" source="retourSupplier.id" link={false}/>
                    <DateField source="retourSupplierDate"/>
                    <ChipField source="state"/>
                    <BooleanField source="returnApproved"/>
                    <BooleanField source="toCredit"/>
                    <BooleanField source="credited"/>
                    <TextField source="remark"/>
                </DatagridConfigurable>
            )}
        </List>
    );
};

export default ServiceList;
