import {
    ArrayInput, AutocompleteInput, BooleanInput, DateField,
    Edit, FormDataConsumer, Labeled, ListButton,
    ReferenceField, ReferenceInput, required, SaveButton,
    SimpleForm,
    SimpleFormIterator, TextField, TextInput, Toolbar, TopToolbar,
    useRecordContext
} from "react-admin";
import {Box, Card, CardContent, Grid, Typography} from "@mui/material";
import {Spacer} from "../Spacer";
import * as React from "react";
import {PriceView, ProductOptionInput} from "./QuoteGeneral";
import AddressInput from "./AddressInput";
import {CustomNumberInput} from "../CustomNumberInput";

const QuoteItems = () => {
    return (
        <ArrayInput source="items">
            <SimpleFormIterator inline disableReordering>
                <ReferenceInput label="Product" reference="products" source="product.id" filter={{ active: true }} validate={[required()]}>
                    <AutocompleteInput sx={{ width: 350 }}/>
                </ReferenceInput>
                <FormDataConsumer>
                    {({
                          formData, // The whole form data
                          scopedFormData, // The data for this item of the ArrayInput
                          getSource, // A function to get the valid source inside an ArrayInput
                          ...rest
                      }) =>
                        scopedFormData && scopedFormData.product ? (
                            <>
                                <PriceView scopedFormData={scopedFormData} selectedProduct={scopedFormData.product}/>
                                <ProductOptionInput scopedFormData={scopedFormData} getSource={getSource} selectedProduct={scopedFormData.product}/>
                            </>
                        ) : null
                    }
                </FormDataConsumer>
                <CustomNumberInput source="qty" validate={[required()]}/>
                <CustomNumberInput source="discountPercent"/>
            </SimpleFormIterator>
        </ArrayInput>
    );
}

const QuoteTitle = () => {
    const record = useRecordContext();
    return record ? (<span>Quote {record.orderNumber}</span>) : null;
};

const QuoteEditActions = () => {
    const record = useRecordContext();
    return (
        <TopToolbar>
            <ListButton/>
        </TopToolbar>
    )
};

const QuoteEditToolbar = () => {
    return (
        <Toolbar>
            <SaveButton alwaysEnable />
        </Toolbar>
    )
};

export const QuoteEdit  = () => {
    const transform = (data) => {
        const sanitizedData = {};
        for (const key in data) {
            if (key === "items" && typeof data[key] === "object" && Array.isArray(data[key])) {
                const newArray = [];
                data[key].forEach((item) => newArray.push(transform(item)));
                sanitizedData[key] = newArray;
            } else if (typeof data[key] !== "string" || data[key].trim().length !== 0) {
                sanitizedData[key] = data[key];
            }
        }

        return sanitizedData;
    };

    return (
        <Edit title={<QuoteTitle/>} actions={<QuoteEditActions/>} component="div" transform={transform} redirect="show">
            <SimpleForm toolbar={<QuoteEditToolbar/>} sanitizeEmptyValues>
                <Box>
                    <Card>
                        <CardContent>
                            <Grid container spacing={1} columns={2}>
                                <Grid item xs={1}>
                                    <Typography variant="h6" gutterBottom>Quote</Typography>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Labeled label="Date">
                                                <DateField source="createdAt" showTime/>
                                            </Labeled>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Labeled label="Order Number">
                                                <TextField source="orderNumber"/>
                                            </Labeled>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <TextInput source="referenceNumber"/>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <BooleanInput source="projectOrder" />
                                        </Grid>
                                    </Grid>
                                    <TextInput source="remark"/>
                                    <Typography variant="h6" gutterBottom>Customer</Typography>
                                    <ReferenceField label="Customer" source="customer.id" reference="relations"/>
                                    <Typography><TextField source="customer.vatNumber"/></Typography>
                                    <Typography><TextField source="customer.email"/></Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography variant="h6" gutterBottom>Shipping Address</Typography>
                                    <AddressInput source="shippingAddress.customerAddressId" otherAddressAllowed type="shipping"/>
                                    <Spacer/>
                                    <Typography variant="h6" gutterBottom>Billing Address</Typography>
                                    <AddressInput source="billingAddress.customerAddressId" type="billing"/>
                                </Grid>
                            </Grid>
                            <Spacer/>
                            <Typography variant="h6" gutterBottom>Items</Typography>
                            <Box>
                                <QuoteItems/>
                            </Box>
                            <Spacer/>
                            <Typography variant="h6" gutterBottom>Totals</Typography>
                            <Box>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <Typography>Discount amount</Typography>
                                        <CustomNumberInput source="discountAmount"
                                                     options={{style: 'currency', currency: 'EUR'}}/>
                                        <Typography>Payment Fee</Typography>
                                        <CustomNumberInput source="paymentFeeAmount"
                                                     options={{style: 'currency', currency: 'EUR'}}/>
                                        <Typography>Shipping</Typography>
                                        <CustomNumberInput source="shippingAmount"
                                                     options={{style: 'currency', currency: 'EUR'}}/>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </SimpleForm>
        </Edit>
    );
};