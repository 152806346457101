import {Box, Button, Card, CardContent, Input} from "@mui/material";
import {useDataProvider, Title, Form, useNotify} from "react-admin";
import {useMutation} from "react-query";
import React, {useState} from "react";
import config from "../config";
import {format, startOfMonth} from "date-fns";

const SyncProductsButton = ({ status }) => {
    const dataProvider = useDataProvider();
    const { mutate, isLoading } = useMutation(
        ['status', status],
        () => dataProvider.syncMagentoProducts(status)
    );
    return (<Button label="SyncProducts" onClick={() => mutate()} disabled={isLoading}>Sync now</Button>);
};

const GenerateProductListButton = () => {
    const notify = useNotify();
    const [loading, setLoading] = useState(false);

    async function downloadFile() {
        setLoading(true);
        const response = await fetch(`${config.serverHost}/api/products/generateProductList`, {
            headers: {
                "Accept": "application/pdf",
                "Authorization": `Bearer ${localStorage.getItem("access_token")}`
            }
        });
        const contentDisposition = response.headers.get('Content-Disposition');
        const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim().replaceAll("\"","");

        const myBlob = await response.blob();
        const objUrl = window.URL.createObjectURL(myBlob);

        let link = document.createElement('a');
        link.href = objUrl;
        link.download = filename;
        link.click();

        setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);

        notify('Download File');
        setLoading(false);
    }

    return (<Button label="GenerateProductList" onClick={downloadFile} disabled={loading}>Generate ProductList</Button>);
};

const GenerateProductListCSVButton = () => {
    const notify = useNotify();
    const [loading, setLoading] = useState(false);

    async function downloadFile() {
        setLoading(true);
        const response = await fetch(`${config.serverHost}/api/products/generateProductListCSV`, {
            headers: {
                "Accept": "application/csv",
                "Authorization": `Bearer ${localStorage.getItem("access_token")}`
            }
        });
        const contentDisposition = response.headers.get('Content-Disposition');
        const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim().replaceAll("\"","");

        const myBlob = await response.blob();
        const objUrl = window.URL.createObjectURL(myBlob);

        let link = document.createElement('a');
        link.href = objUrl;
        link.download = filename;
        link.click();

        setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);

        notify('Download File');
        setLoading(false);
    }

    return (<Button label="GenerateProductListCSV" onClick={downloadFile} disabled={loading}>Generate ProductList CSV</Button>);
};

const AdminConfiguration = () => {
    const [syncDate, setSyncDate] = useState(null);
    const [intrastatDate, setIntrastatDate] = useState(startOfMonth(new Date()));
    const dataProvider = useDataProvider();
    const {mutate, isLoading} = useMutation(
        ['syncDate', syncDate],
        () =>  {
            dataProvider.syncMagentoOrdersFromDate(syncDate)
        });

    function syncOrders() {
        mutate();
    }

    const GenerateIntrastatCSVButton = () => {
        const notify = useNotify();
        const [loading, setLoading] = useState(false);

        async function downloadFile() {
            setLoading(true);
            const response = await fetch(`${config.serverHost}/api/orders/generateIntrastatCSV/` + format(intrastatDate,'yyyy-MM-dd'), {
                headers: {
                    "Accept": "application/csv",
                    "Authorization": `Bearer ${localStorage.getItem("access_token")}`
                }
            });
            const contentDisposition = response.headers.get('Content-Disposition');
            const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim().replaceAll("\"","");

            const myBlob = await response.blob();
            const objUrl = window.URL.createObjectURL(myBlob);

            let link = document.createElement('a');
            link.href = objUrl;
            link.download = filename;
            link.click();

            setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);

            notify('Download File');
            setLoading(false);
        }

        return (<Button label="GenerateIntrastatCSV" onClick={downloadFile} disabled={loading}>Generate Intrastat CSV</Button>);
    };

    return (
      <Card>
          <Title title="Trigger synchronizations" />
          <CardContent>
              <Box sx={{ width: '10em', display: 'inline-block' }}>
                  {"Sync Magento Products"}
              </Box>
              <SyncProductsButton status="1"/>
          </CardContent>
          <CardContent>
              <Box sx={{ width: '10em', display: 'inline-block' }}>
                  {"Sync Magento Orders"}
              </Box>
              <Form onSubmit={syncOrders}>
                  <input type="datetime-local" value={syncDate} onChange={(e) => setSyncDate(e.target.value)}/>
                  <Button type="submit" disabled={isLoading}>Sync now</Button>
              </Form>
          </CardContent>
          <CardContent>
              <Box sx={{ width: '10em', display: 'inline-block' }}>
                  {"Generate dealers product list"}
              </Box>
              <GenerateProductListButton/>
              <GenerateProductListCSVButton/>
          </CardContent>
          <CardContent>
              <Box sx={{ width: '10em', display: 'inline-block' }}>
                  {"Generate Intrastat"}
              </Box>
              <Input type="month" value={format(intrastatDate,'yyyy-MM')} onChange={(e) => setIntrastatDate(new Date(e.target.value))}/>
              <GenerateIntrastatCSVButton intrastatDate={{intrastatDate}}/>
          </CardContent>
      </Card>
    );
}

export default AdminConfiguration;