import {NumberField, useRecordContext} from "react-admin";
import {Tooltip} from "@mui/material";
import * as React from "react";

const MargeCurrencyField = ({ source, total, currency, dealerOrder }) => {
    const record = useRecordContext();
    const marge = record && (record[source] * 100 / record[total]).toFixed(0);
    return (
        <Tooltip title={marge + " %"}>
            <NumberField sx={{ color: marge < (record[dealerOrder] ? 10 : 20) ? 'red' :  marge < (record[dealerOrder] ? 15 : 30) ? 'orange' : '' }} source={source} options={{ style: 'currency', currency: record[currency] }} />
        </Tooltip>
    );
};

export default MargeCurrencyField;