import * as React from "react";
import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    Edit,
    SimpleForm,
    TextInput,
    Create,
    SearchInput, AutocompleteInput, useRecordContext
} from 'react-admin';
import {useMediaQuery} from "@mui/material";

const modelFilters = [
    <SearchInput source="q" alwaysOn />,
    <TextInput source="code" label="Code" allowEmpty/>
];

export const ModelList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List {...props} filters={modelFilters} sort={{ field: 'code', order: 'ASC' }} perPage={20}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.code}
                    secondaryText={record => record.name}
                />
            ) : (
                <Datagrid rowClick="edit">
                    <TextField source="code"/>
                    <TextField source="name"/>
                    <TextField source="vehicleBrand"/>
                </Datagrid>
            )}
        </List>
    );
};

export const ModelEdit = props => (
    <Edit {...props} title={<ModelTitle/>}>
        <SimpleForm>
            <TextInput InputProps={{ disabled: true }} source="code" />
            <TextInput source="name" />
            <AutocompleteInput source="vehicleBrand" choices={[
                { id: 'BMW', name: 'BMW' },
                { id: 'AUDI', name: 'Audi' },
                { id: 'HONDA', name: 'Honda' },
                { id: 'MCLAREN', name: 'Mclaren' },
                { id: 'MERCEDES', name: 'Mercedes' },
                { id: 'NISSAN', name: 'Nissan' },
                { id: 'PORSCHE', name: 'Porsche' },
                { id: 'TOYOTA', name: 'Toyota' },
                { id: 'VW', name: 'Volkswagen' },
            ]} />
        </SimpleForm>
    </Edit>
);

export const ModelCreate = props => (
    <Create {...props} title="Create a Model">
        <SimpleForm>
            <TextInput source="code" />
            <TextInput source="name" />
            <AutocompleteInput source="vehicleBrand" choices={[
                { id: 'BMW', name: 'BMW' },
                { id: 'AUDI', name: 'Audi' },
                { id: 'HONDA', name: 'Honda' },
                { id: 'MCLAREN', name: 'Mclaren' },
                { id: 'MERCEDES', name: 'Mercedes' },
                { id: 'NISSAN', name: 'Nissan' },
                { id: 'PORSCHE', name: 'Porsche' },
                { id: 'TOYOTA', name: 'Toyota' },
                { id: 'VW', name: 'Volkswagen' },
            ]} />
        </SimpleForm>
    </Create>
);

const ModelTitle = () => {
    const record = useRecordContext();
    return <span>Model {record ? `"${record.name}"` : ''}</span>;
};