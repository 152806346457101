import {NumberField, ReferenceField, useRecordContext} from "react-admin";
import {Tooltip} from "@mui/material";
import * as React from "react";

const CustomProductField = ({ source }) => {
    const record = useRecordContext();
    const onStock = record && (record["product"].stockManagement ? record["product"].physicalStock : 99);
    return (
        <ReferenceField label="Product" reference="products" source={source}
                        sx={{'& .RaReferenceField-link>*': { color: (onStock <= 0 || onStock < record["qty"]) ? 'red' :  (onStock === record["qty"]) ? 'orange' : '' }}} />
    );
};

export default CustomProductField;