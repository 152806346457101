import React, {useMemo, useState} from "react";
import {Card, CardContent, CardHeader, MenuItem, Select} from "@mui/material";
import {useGetList} from "react-admin";
import {format, startOfDay, subYears} from "date-fns";
import {Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";

const CurrencyRate = () => {
    const aYearAgo = useMemo(() => subYears(startOfDay(new Date()), 1), []);
    const [currency, setCurrency] = useState("USD");

    const { data: currencyRates } = useGetList('currencyRates', {
        filter: { validFrom_gte: format(aYearAgo, 'yyyy-MM-dd'), currency : currency },
        sort: { field: 'validFrom', order: 'ASC' },
        pagination: { page: 1, perPage: 400 },
    });

    if (!currencyRates) return null;

    const getData = (currencyRates): [] => {
        return  Array.from(currencyRates).map(c => ({
            validFrom: c.validFrom,
            rate: c.rate,
        }));
    };

    return (
        <Card>
            <CardHeader title="Currency Rate History" />
            <CardContent>
                <div>
                    <Select label="Currency" value={currency}
                            onChange={(e) => setCurrency(e.target.value)}
                            required>
                        <MenuItem value="USD">USD</MenuItem>;
                        <MenuItem value="GBP">GBP</MenuItem>;
                    </Select>
                </div>
                <div style={{ width: '100%', height: 300 }}>
                    <ResponsiveContainer>
                        <AreaChart data={getData(currencyRates)}>
                            <defs>
                                <linearGradient
                                    id="colorUv"
                                    x1="0"
                                    y1="0"
                                    x2="0"
                                    y2="1"
                                >
                                    <stop
                                        offset="5%"
                                        stopColor="#8884d8"
                                        stopOpacity={0.8}
                                    />
                                    <stop
                                        offset="95%"
                                        stopColor="#8884d8"
                                        stopOpacity={0}
                                    />
                                </linearGradient>
                            </defs>
                            <XAxis
                                dataKey="validFrom"
                                name="Date"
                            />
                            <YAxis dataKey="rate" name="Rate" type="number" domain={['auto', 'auto']} />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip
                                cursor={{ strokeDasharray: '3 3' }}
                            />
                            <Area
                                type="monotone"
                                dataKey="rate"
                                stroke="#8884d8"
                                strokeWidth={2}
                                fill="url(#colorUv)"
                            />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    );

}

export default CurrencyRate;