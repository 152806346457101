import * as React from "react";
import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    Edit,
    SimpleForm,
    TextInput,
    Create,
    SearchInput, useRecordContext
} from 'react-admin';
import {useMediaQuery} from "@mui/material";
import {CustomNumberInput} from "./CustomNumberInput";

const brandFilters = [
    <SearchInput source="q" alwaysOn />,
    <TextInput source="code" label="Code" allowEmpty/>
];

export const BrandList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List filters={brandFilters} sort={{ field: 'code', order: 'ASC' }} perPage={20}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.code}
                    secondaryText={record => record.name}
                />
            ) : (
                <Datagrid rowClick="edit">
                    <TextField source="code"/>
                    <TextField source="name"/>
                    <TextField source="magentoBrandId" />
                    <TextField source="groupedBrandName" />
                </Datagrid>
            )}
        </List>
    );
};

export const BrandEdit = props => (
    <Edit title={<BrandTitle/>}>
        <SimpleForm>
            <TextInput InputProps={{ disabled: true }} source="code" />
            <TextInput source="name" />
            <CustomNumberInput source="magentoBrandId"/>
            <TextInput source="groupedBrandName"/>
        </SimpleForm>
    </Edit>
);

export const BrandCreate = props => (
    <Create title="Create a Brand">
        <SimpleForm>
            <TextInput source="code" />
            <TextInput source="name" />
            <CustomNumberInput source="magentoBrandId"/>
            <TextInput source="groupedBrandName"/>
        </SimpleForm>
    </Create>
);

const BrandTitle = () => {
    const record = useRecordContext();
    return <span>Brand {record ? `"${record.name}"` : ''}</span>;
};