import * as React from "react";
import { useMediaQuery } from '@mui/material';
import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    Edit,
    SimpleForm,
    TextInput,
    Create,
    SearchInput, useRecordContext
} from 'react-admin';

const productTypeFilters = [
    <SearchInput source="q" alwaysOn />,
    <TextInput source="code" label="Code" allowEmpty/>
];

export const ProductTypeList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List {...props} filters={productTypeFilters} sort={{ field: 'code', order: 'ASC' }} perPage={20}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.code}
                    secondaryText={record => record.name}
                />
            ) : (
                <Datagrid rowClick="edit">
                    <TextField source="code"/>
                    <TextField source="name"/>
                </Datagrid>
            )}
        </List>
    );
};

export const ProductTypeEdit = props => (
    <Edit {...props} title={<ProductTypeTitle/>}>
        <SimpleForm>
            <TextInput InputProps={{ disabled: true }} source="code" />
            <TextInput source="name" />
        </SimpleForm>
    </Edit>
);

export const ProductTypeCreate = props => (
    <Create {...props} title="Create a ProductType">
        <SimpleForm>
            <TextInput source="code" />
            <TextInput source="name" />
        </SimpleForm>
    </Create>
);

const ProductTypeTitle = () => {
    const record = useRecordContext();
    return <span>ProductType {record ? `"${record.name}"` : ''}</span>;
};