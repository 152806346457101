import * as React from 'react';

import {Box, Card, Typography, useMediaQuery} from "@mui/material";
import TopDashboard from "../TopDashboard";
import ProductsOnStockButSetOutOfStock from "./ProductsOnStockButSetOutOfStock";
import ProductsNeedsToBeReordered from "./ProductsNeedsToBeReordered";
import {Spacer} from "../../Spacer";

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
};

export default () => {
    const isXSmall = useMediaQuery(theme =>
        theme.breakpoints.down('sm')
    );
    const isSmall = useMediaQuery(theme =>
        theme.breakpoints.down('lg')
    );

    const TopDashboard = () => {
        return (
            <Card
                sx={{
                    padding: '20px',
                    marginTop: 2,
                    marginBottom: '1em',
                }}
            >
                <Box display="flex">
                    <Box flex="1">
                        <Typography variant="h5" component="h2" gutterBottom>
                            Product - Stock Alarms
                        </Typography>
                    </Box>
                </Box>
            </Card>
        );
    };

    return (
        isXSmall ? (
            <div>
                <div style={styles.flexColumn}>
                    <TopDashboard/>
                    <ProductsOnStockButSetOutOfStock />
                    <Spacer />
                    <ProductsNeedsToBeReordered/>
                </div>
            </div>
        ) : isSmall ? (
            <div style={styles.flexColumn}>
                <div style={styles.singleCol}>
                    <TopDashboard/>
                </div>
                <div style={styles.flex}>
                    <ProductsOnStockButSetOutOfStock/>
                    <Spacer />
                    <ProductsNeedsToBeReordered/>
                </div>
            </div>
        ) : (
            <div>
                <TopDashboard/>
                <div style={styles.flex}>
                    <div style={styles.leftCol}>
                        <div style={styles.flex}>
                            <ProductsOnStockButSetOutOfStock/>
                            <Spacer />
                            <ProductsNeedsToBeReordered/>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};