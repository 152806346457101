import {
    Create,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    required,
    useNotify, useRedirect, AutocompleteInput
} from "react-admin";
import {useLocation} from "react-router";
import {CustomNumberInput} from "../CustomNumberInput";
import * as React from "react";

const today = new Date();
const ProductCoreCreate = (props) => {
    const location = useLocation();
    const product_id =
        location.state && location.state.record
            ? location.state.record.product.id
            : undefined;

    const redirectPath = location.state && location.state.record
        ? location.state.record.redirectPath
        : undefined;

    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = (data) => {
        notify(`Product Core saved`);
        if (redirectPath) {
            redirect(redirectPath);
        } else {
            const r = product_id ? `/products/${product_id}/4` : false;
            redirect(r);
        }
    };

    return (
        <Create {...props} mutationOptions={{onSuccess}}>
            <SimpleForm>
                <ReferenceInput source="product.id" reference="products">
                    <SelectInput optionText="name" validate={required()}/>
                </ReferenceInput>
                <ReferenceInput source="purchaseItem.id" reference="purchaseItems" filter={{ product_id : product_id}}>
                    <SelectInput validate={required()}/>
                </ReferenceInput>
                <CustomNumberInput source="price" validate={required()}/>
                <AutocompleteInput source="priceCurrency" choices={[
                    {id: 'EUR', name: 'EUR'},
                    {id: 'USD', name: 'USD'},
                    {id: 'GBP', name: 'GBP'}
                ]}/>
                <CustomNumberInput source="amount" validate={required()}/>
            </SimpleForm>
        </Create>
    );
}

export default ProductCoreCreate;