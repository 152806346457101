import {Card, CardContent, CardHeader} from "@mui/material";
import React, {useState} from "react";
import styles from './Reports.module.css'
import {useDataProvider} from "react-admin";
import {useQuery} from "react-query";

const Reports = (props) => {
    const [startDate, setStartDate] = useState('2022-12-01');
    const [endDate, setEndDate] = useState('2022-12-31');

    const dataProvider = useDataProvider();
    const { data, isLoading, error } = useQuery(
        ['startDate', 'startDate'],
        () => { dataProvider.getTopCustomers(startDate, endDate);}
    );

    const startDateChangeHandler = (event) => {
        setStartDate(event.target.value);
    }

    const endDateChangeHandler = (event) => {
        setEndDate(event.target.value);
    }

    return (
        <Card>
            <CardHeader title="Reports" />
            <CardContent>
                <form>
                    <div className={styles.reports__controls}>
                        <div className={styles.reports__control}>
                            <label>Start Date</label>
                            <input type="date" value={startDate} onChange={startDateChangeHandler}/>
                        </div>
                        <div className={styles.reports__control}>
                            <label>End Date</label>
                            <input type="date" value={endDate} onChange={endDateChangeHandler}/>
                        </div>
                    </div>
                    <div className={styles.reports__actions}>
                        <button type="submit">Search</button>
                    </div>
                </form>
                <div>
                    <ul>
                        {data && data.data.map(topCustomer =>
                            <li>{topCustomer}</li>
                        )}
                    </ul>
                </div>
            </CardContent>
        </Card>
    );
}

export default Reports;