import * as React from 'react';
import DollarIcon from '@mui/icons-material/AttachMoney';

import CardWithIcon from './CardWithIcon';

const MonthlyRevenue = (props) => {
    return (
        <CardWithIcon
            to="/orders"
            icon={DollarIcon}
            title="Revenue"
            subtitle={props.value}
        />
    );
};

export default MonthlyRevenue;