import { useState } from 'react';
import {
    Button,
    useNotify,
    useRefresh,
    useDataProvider, useRecordContext,
} from 'react-admin';
import {
    TextField,
    Select,
    MenuItem,
    InputLabel,
    FormControl, FormControlLabel,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Toolbar,
    Typography,
    Grid,
    Box,
    Checkbox
} from "@mui/material";
import {useMutation, useQuery} from "react-query";

function EnhancedTableHead(props) {
        return (
        <TableHead>
            <TableRow>
                <TableCell padding="normal">Product name</TableCell>
                <TableCell align="right" padding="normal">Qty</TableCell>
                <TableCell align="right" padding="normal">Already Received</TableCell>
                <TableCell align="right" padding="normal">Received Now</TableCell>
            </TableRow>
        </TableHead>
    );
}

const EnhancedTableToolbar = (props) => {
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
            }}
        >
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Items to receive
                </Typography>
        </Toolbar>
    );
};

const CreateReceiptPanel = () => {
    const record = useRecordContext();
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState([]);
    const [errorTextSelected, setErrorTextSelected] = useState(null);
    const [carrier, setCarrier] = useState("DHL");
    const [trackingNumber, setTrackingNumber] = useState("");
    const [dropShipment, setDropShipment] = useState(false);
    const [errorTextTrackingNumber, setErrorTextTrackingNumber] = useState(null);
    const refresh = useRefresh();
    const notify = useNotify();
    const dataProvider = useDataProvider();

    const { data, isLoadingCarriers, error } = useQuery(
        ['carriers', 'getCarriers'],
        () => dataProvider.getCarriers()
    );

    const { mutate, isLoading } = useMutation(
        ['receipt', 'create'],
        () => dataProvider.createReceipt(record.id, selected, carrier, trackingNumber, dropShipment),
        {
            onSuccess: () => {
                refresh();
                notify('Receipt created');
            },
            onError: error => notify('Error: receipt not created', { type: 'warning' }),
        }
    );

    const handleConfirm = (e) => {
        setErrorTextTrackingNumber("");
        setErrorTextSelected("");

        if (selected.length === 0) {
            setErrorTextSelected("At least one item to receipt should be checked");
            return;
        }
        if (trackingNumber == null || trackingNumber.length === 0) {
            setErrorTextTrackingNumber("Required field");
            return;
        }
        e.preventDefault();
        mutate();
        setSelected([])
        setTrackingNumber("");
        setDropShipment(false);
        setOpen(false);

    };

    if (isLoadingCarriers) return <p>LOADING</p>;
    if (error) return <p>ERROR</p>;
    if (!data) return null;

    const handleOnchangeQtyReceived = (event, name, totalQty, alreadyReceivedQty, qty) => {
        const existingItemIndex = selected.findIndex(item => item.startsWith(name + "-"));
        const existingItem = selected[existingItemIndex];
        let updatedItems = [];
        setErrorTextSelected("");

        if (totalQty - alreadyReceivedQty < qty) {
            let maxValue = totalQty - alreadyReceivedQty;
            setErrorTextSelected("Quantity Received Now can't be greater than " + maxValue);
            return;
        }

        if (existingItem) {
            if (qty === '' || qty === "0") {
                updatedItems = selected.filter(item => !item.startsWith(name + "-"));
            } else {
                const updatedItem = name + "-" + qty;
                updatedItems = [...selected];
                updatedItems[existingItemIndex] = updatedItem;
            }
        } else {
            if (qty !== '' && qty !== "0") {
                updatedItems = selected.concat(name + "-" + qty);
            } else {
                updatedItems = [...selected];
            }
        }

        setSelected(updatedItems);
    };

    const handlePanelClose = () => setOpen(false);

    return (
        <>
            <Button label="Create Receipt" onClick={() => setOpen(prev => !prev)} />
            {open &&
                <form onSubmit={handleConfirm} id="myform">
                    <Box sx={{ p: 2, border: '1px dashed grey' }}>
                        <Grid item xs={2}>
                            <Typography
                                sx={{ fontWeight: 400, fontSize: '1rem', color: '#d32f2f' }}
                                component="div"
                            >
                                {errorTextSelected}
                            </Typography>
                            <EnhancedTableToolbar />
                            <TableContainer>
                                <Table sx={{ minWidth: 450 }} aria-label="simple table" size={'small'}>
                                    <EnhancedTableHead/>
                                    <TableBody>
                                        {record.items
                                            .map((row, index) => {
                                                /*if (row.qty != row.qtyReceived) {*/
                                                    const labelId = `enhanced-table-checkbox-${index}`;

                                                    return (
                                                        <TableRow
                                                            hover
                                                            tabIndex={-1}
                                                            key={row.id + "-" + row.qty}
                                                        >
                                                            <TableCell
                                                                component="th"
                                                                id={labelId}
                                                                scope="row"
                                                                padding="none"
                                                            >
                                                                {row.product.name} {row.product.skuSupplier}
                                                            </TableCell>
                                                            <TableCell align="right">{row.qty}</TableCell>
                                                            <TableCell align="right">{row.qtyReceived}</TableCell>
                                                            <TableCell padding="none">
                                                                <TextField value={row.qtyReceivedNow}
                                                                    label="Qty Received now"
                                                                    type="number"
                                                                    onChange={(event) => handleOnchangeQtyReceived(event, row.id, row.qty, row.qtyReceived, event.target.value)}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                /*}*/
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl>
                               <InputLabel htmlFor="carrier-select" id="carrier-select-label">Carrier</InputLabel>
                               <Select id="carrier-select" labelId="carrier-select-label" label="Carrier" value={carrier}
                                        onChange={(e) => setCarrier(e.target.value)}
                                        required
                               >
                                    {data.data.map((record) => {
                                        return <MenuItem value={record}>{record}</MenuItem>;
                                    })}
                               </Select>
                            </FormControl>
                        </Grid>
                        <Grid>
                            <FormControl>
                                <TextField
                                    value={trackingNumber}
                                    label="Tracking Number"
                                    type="text"
                                    onChange={(e) => setTrackingNumber(e.target.value)}
                                    required
                                    error={errorTextTrackingNumber}
                                    helperText={errorTextTrackingNumber}
                                />
                            </FormControl>
                        </Grid>
                        <Grid>
                            <FormControlLabel control={<Checkbox value={dropShipment}
                                      onChange={(e) => setDropShipment(e.target.checked)}/>}
                                              label="Drop Shipment"/>
                        </Grid>
                        <Grid>
                            <Button onClick={handlePanelClose}>Cancel</Button>
                            <Button onClick={handleConfirm} type="submit" form="myform">Create</Button>
                        </Grid>
                    </Box>
                </form>
            }
        </>
    );
};

export default CreateReceiptPanel;