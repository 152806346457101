import * as React from 'react';
import { Card, CardHeader, CardContent } from '@mui/material';
import {
    ResponsiveContainer,
    Tooltip, PieChart, Pie, Cell, LabelList, Area
} from 'recharts';
import {Identifier} from "react-admin";

const lastMonthBrands = new Set([]);

const aggregateOrdersByBrand = (orders): { [key]: number } =>
    orders
        .filter((order) => order.state !== 'CANCELED')
        .reduce((acc, curr) => {
            curr.items.forEach(item => {
                const brand = item.product.brand;
                if (!acc[brand.name]) {
                    acc[brand.name] = 0;
                    lastMonthBrands.add(brand.name);
                }
                acc[brand.name] += item.margin;
            });
            return acc;
        }, {});

const getRevenuePerBrand = (orders): TotalByBrand[] => {
    const brandWithRevenue = aggregateOrdersByBrand(orders);
    const l = Array.from(lastMonthBrands).map(brand => ({
        brand: brand,
        total: brandWithRevenue[brand] || 0,
    }));
    return l;
};

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const BrandChart = (props: { orders?: Order[] }) => {
    const {orders} = props;
    if (!orders) return null;
    const data = getRevenuePerBrand(orders);

    return (
        <Card>
            <CardHeader title="Vendor History"/>
            <CardContent>
                <div style={{width: '100%', height: 300}}>
                    <ResponsiveContainer>
                        <PieChart width={800} height={300}>
                            <Pie
                                data={data}
                                cx="50%"
                                cy="50%"
                                outerRadius={130}
                                fill="#8884d8"
                                dataKey="total"
                                nameKey="brand"
                            >
                                {/*<LabelList dataKey="brand" position="outside"/>*/}
                                {data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip
                                cursor={{ strokeDasharray: '3 3' }}
                                formatter={(value: any) =>
                                    new Intl.NumberFormat(undefined, {
                                        style: 'currency',
                                        currency: 'EUR',
                                    }).format(value)
                                }
                                labelFormatter={(label: any) =>
                                    label
                                }
                            />
                        </PieChart>
                    </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    );
};

interface TotalByBrand {
    Brand: Identifier;
    total: number;
}

export default BrandChart;
