import * as React from 'react';
import CardWithIcon from "../CardWithIcon";
import WarningIcon from '@mui/icons-material/Warning';
import {ListBase, SimpleList, WithListContext} from "react-admin";

const ProductsOnStockButSetOutOfStock = () => {
    return (
        <ListBase
            resource="products"
            filter={{
                active: true,
                onCustomerWebshop: true,
                inStock: false,
                physicalStock_gt: 0,
            }}
            sort={{ field: 'createdAt', order: 'DESC' }}
            perPage={100}
            disableSyncWithLocation
        >
            <CardWithIcon
                to="/products"
                icon={WarningIcon}
                title="Products with stock but out stock on website"
                subtitle={
                    <WithListContext render={({ total }) => <>{total}</>} />
                }
            >
                <SimpleList primaryText="%{sku}" secondaryText="%{name}" tertiaryText="%{physicalStock}"/>
            </CardWithIcon>
        </ListBase>
    );
};

export default ProductsOnStockButSetOutOfStock;