import {
    useRecordContext,
    Button,
    Link,
} from "react-admin";
import {Box, Typography} from "@mui/material";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import * as React from "react";

const AddNewCommentButton = () => {
    const record = useRecordContext();
    return record ? (
        <Button
            component={Link}
            to={{
                pathname: "/orderComments/create",
                // Here we specify the initial record for the create view
                state: { record: { orderId: record.id } },
            }}
            label="Add a comment"
        >
            <ChatBubbleIcon />
        </Button>) : null
};


const OrderCommentPanel = () => {
    const record = useRecordContext();
    return (
        <Box sx={{width: '400px', margin: '1em'}}>
            <Typography variant="h6">Comments</Typography>
            <Typography variant="body2" component={'span'}>
                <Box>
                    {record && record.comments.map(comment => (
                        <Typography key={comment.id} component={'span'}>
                            <b>{comment.createdAt}</b><br/>
                            <small>{comment.description}</small><br/>
                        </Typography>
                    ))}
                </Box>
            </Typography>
            <Typography>
                <AddNewCommentButton/>
            </Typography>
        </Box>
    );
}

export default OrderCommentPanel;