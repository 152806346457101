import React from "react";
import {Admin, Resource, ListGuesser, EditGuesser, fetchUtils, CustomRoutes} from 'react-admin';
import myDataProvider from './myDataProvider';
import { BrandList, BrandEdit, BrandCreate } from "./components/Brands";
import { OrderList } from "./components/order/Orders";
import Dashboard from "./components/dashboard/Dashboard";
import NotFound from "./components/NotFound";
import MyLayout from "./components/MyLayout";
import {RelationCreate, RelationEdit, RelationList} from "./components/Relations";
import {OrderEdit} from "./components/order/OrderEdit";
import {ModelList, ModelEdit, ModelCreate} from "./components/Models";
import {ProductTypeList, ProductTypeEdit, ProductTypeCreate} from "./components/ProductTypes";
import {MsalAuthenticationTemplate, AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";
import config from "./config";
import AdminConfiguration from "./components/AdminConfiguration";
import {Route} from 'react-router';
import {QuoteCreate} from "./components/quote/QuoteCreate";
import {QuoteEdit} from "./components/quote/QuoteEdit";
import {InteractionType} from "@azure/msal-browser";
import {msalInstance} from "./index";
import {PurchaseList} from "./components/purchase/Purchases";
import {PurchaseCreate, PurchaseEdit} from "./components/purchase/PurchaseEdit";
import {OrderCommentCreate} from "./components/order/OrderCommentCreate";
import {QuoteList} from "./components/quote/Quotes";
import {QuoteShow} from "./components/quote/QuoteShow";
import ProductList from "./components/product/Products";
import ProductEdit from "./components/product/ProductEdit";
import ProductCreate from "./components/product/ProductCreate";
import Reports from "./components/reports/Reports";
import InventoryAdjustmentCreate from "./components/product/InventoryAdjustmentCreate";
import {PurchaseShow} from "./components/purchase/PurchaseShow";
import ReportStock from "./components/reports/ReportStock";
import InventoryValue from "./components/reports/InventoryValue";
import CurrencyRate from "./components/reports/CurrencyRate";
import RevenueReport from "./components/reports/RevenueReport";
import ProductDashboard from "./components/dashboard/product/ProductDashboard";
import ServiceList from "./components/service/Services";
import {ServiceCreate, ServiceEdit} from "./components/service/ServiceEdit";
import StockCount from "./components/stockCount/StockCount";
import ProductCoreCreate from "./components/product/ProducCoreCreate";
import {ProductCores} from "./components/productCore/ProductCores";
import {ProductCoreEdit} from "./components/productCore/ProductCoreEdit";

async function getAccessToken() {
    const accounts = msalInstance.getAllAccounts();

    if (accounts.length > 0) {
        const request = {
            scopes: config.protectedResources.apiStock.scopes,
            account: accounts[0]
        }
        const accessToken = await msalInstance.acquireTokenSilent(request).then((response) => {
            localStorage.setItem("access_token", response.accessToken);
            const roles = accounts[0].idTokenClaims?.roles ?? [];
            localStorage.setItem("permissions", roles.map((role) => config.rolesPermissionMap[role]));
            return response.accessToken;
        }).catch(error => {
            // Do not fallback to interaction when running outside the context of MsalProvider. Interaction should always be done inside context.
            console.log(error);
            return null;
        });

        return accessToken;
    }

    return null;
}

const httpClient = (url, options = {}) => {
    const accessToken = getAccessToken();

    if (accessToken) {
        if (!options.headers) {
            options.headers = new Headers({Accept: "application/json"});
        }
        options.headers.set("Authorization", `Bearer ${localStorage.getItem("access_token")}`);
    }

    return fetchUtils.fetchJson(url, options);
};

const dataProvider = myDataProvider(`${config.serverHost}/api`, httpClient);

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export default function App() {

    const authRequest = {
        ...config.loginRequest
    };

    return(
        <div className="App">
            <AuthenticatedTemplate>
                <span>Only authenticated users can see me.</span>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <span>Only unauthenticated users can see me.</span>
            </UnauthenticatedTemplate>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest}>
                <Admin title="PureTurbos - Admin" dashboard={Dashboard} dataProvider={dataProvider}
                       catchAll={NotFound} layout={MyLayout}>
                    <Resource name="purchases" list={PurchaseList} edit={PurchaseEdit} create={PurchaseCreate} show={PurchaseShow} recordRepresentation={(record) => `${record.orderNumber}`}/>
                    <Resource name="products" list={ProductList} edit={ProductEdit} create={ProductCreate} recordRepresentation={(record) => `${record.name}`}/>
                    <Resource name="productCores" create={ProductCoreCreate} list={ProductCores} edit={ProductCoreEdit}/>
                    <Resource name="relations" list={RelationList} edit={RelationEdit} create={RelationCreate} recordRepresentation={(record) => `${record.company ? record.company : record.lastName + ', ' + record.firstName}`}/>

                    {/*{localStorage.getItem("permissions").includes("admin") ? (
                        <>*/}
                            <Resource name="inventoryAdjustments" create={InventoryAdjustmentCreate}/>
                            <Resource name='inventoryAdjustmentReasons' recordRepresentation={(record) => `${record.name}`}/>
                            <Resource name='brands' list={BrandList} edit={BrandEdit} create={BrandCreate} recordRepresentation={(record) => `${record.name}`}/>
                            <Resource name='models' list={ModelList} edit={ModelEdit} create={ModelCreate} recordRepresentation={(record) => `${record.name}`}/>
                            <Resource name='productTypes' list={ProductTypeList} edit={ProductTypeEdit} create={ProductTypeCreate} recordRepresentation={(record) => `${record.name}`}/>
                            <Resource name='orders' list={OrderList} edit={OrderEdit} recordRepresentation={(record) => `${record.orderNumber}`}/>
                            <Resource name='quotes' list={QuoteList} show={QuoteShow} create={QuoteCreate} edit={QuoteEdit} recordRepresentation={(record) => `${record.orderNumber}`}/>
                            <Resource name='carriers' />
                            <Resource name='currencyRates'/>
                            <Resource name='orderComments' create={OrderCommentCreate}/>
                            <Resource name='countries' recordRepresentation={(record) => `${record.fullName}`}/>
                            <Resource name='inventorySummaries' list={ReportStock}/>
                            <Resource name='inventoryValues' list={InventoryValue}/>
                            <Resource name='marginPerBrandPerMonths' list={RevenueReport}/>
                            <Resource name='orderItems' />
                            <Resource name='purchaseItems' recordRepresentation={(record) => `${record.purchase.orderNumber + ' - ' + record.product.name}`}/>
                            <Resource name='services' list={ServiceList} edit={ServiceEdit} create={ServiceCreate}/>
                            <CustomRoutes>
                                <Route path="/adminConfiguration" element={<AdminConfiguration/>}/>
                                <Route path="/currencyRate" element={<CurrencyRate/>}/>
                                <Route path="/reports" element={<Reports/>}/>
                                <Route path="/productDashboard" element={<ProductDashboard/>}/>
                                <Route path="/stockCount" element={<StockCount/>}/>
                            </CustomRoutes>
                        {/*</>
                    ) : null}*/}
                </Admin>
            </MsalAuthenticationTemplate>
        </div>
    );
};