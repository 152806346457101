import * as React from "react";
import {
    Create,
    ReferenceInput,
    SimpleForm,
    AutocompleteInput, FormDataConsumer,
    ArrayInput, SimpleFormIterator, required, CreateButton, TextInput, BooleanInput,
} from "react-admin";
import {Box, Card, CardContent, Grid, Typography} from "@mui/material";
import {Spacer} from "../Spacer";
import {PriceView, ProductOptionInput} from "./QuoteGeneral";
import AddressInput from "./AddressInput";
import {CustomNumberInput} from "../CustomNumberInput";

const QuoteItems = (props, source) => {
    return (
        <ArrayInput source="items">
            <SimpleFormIterator inline disableReordering>
                <ReferenceInput label="Product" reference="products" source="product" filter={{ active: true }} validate={[required()]}  >
                    <AutocompleteInput sx={{ width: 350 }}/>
                </ReferenceInput>
                <FormDataConsumer>
                    {({
                          formData, // The whole form data
                          scopedFormData, // The data for this item of the ArrayInput
                          getSource, // A function to get the valid source inside an ArrayInput
                          ...rest
                      }) =>
                        scopedFormData && scopedFormData.product ? (
                            <>
                                <PriceView scopedFormData={scopedFormData} selectedProduct={scopedFormData.product}/>
                                <ProductOptionInput scopedFormData={scopedFormData} getSource={getSource} selectedProduct={scopedFormData.product}/>
                            </>
                        ) : null
                    }
                </FormDataConsumer>
                <CustomNumberInput source="qty" validate={[required()]}/>
                <CustomNumberInput source="discountPercent"/>
            </SimpleFormIterator>
        </ArrayInput>
    );

}

export const QuoteCreate = () => {
    return (
        <Create title="Create a Quote" redirect="list">
            <SimpleForm>
                <Box maxWidth="80em" minWidth="800px">
                    <Card>
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <Typography variant="h6" gutterBottom>Customer</Typography>
                                    <ReferenceInput label="Customer" source="customer" reference="relations"
                                                    filter={{ customer: true, active: true }} >
                                        <AutocompleteInput validate={[required()]} fullWidth/>
                                    </ReferenceInput>
                                    <Grid>
                                        <CreateButton resource="relations" label="Create Customer" />
                                    </Grid>
                                    <Spacer/>
                                    <Typography variant="h8">Shipping Address</Typography>
                                    <AddressInput source="shippingAddress.customerAddressId" otherAddressAllowed type="shipping"/>
                                    <Spacer/>
                                    <Typography variant="h8">Billing Address</Typography>
                                    <AddressInput source="billingAddress.customerAddressId" type="billing"/>
                                    <Spacer/>
                                    <TextInput source="referenceNumber"/>
                                    <BooleanInput source="projectOrder" />
                                </Grid>
                            </Grid>
                            <Spacer/>
                            <Typography variant="h6" gutterBottom>Items</Typography>
                            <Box>
                                <QuoteItems/>
                            </Box>
                            <Spacer/>
                            <Typography variant="h6" gutterBottom>Totals</Typography>
                            <Box>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <Typography>Discount amount</Typography>
                                        <CustomNumberInput source="discountAmount"
                                                     options={{style: 'currency', currency: 'EUR'}}/>
                                        <Typography>Payment Fee</Typography>
                                        <CustomNumberInput source="paymentFeeAmount"
                                                     options={{style: 'currency', currency: 'EUR'}}/>
                                        <Typography>Shipping</Typography>
                                        <CustomNumberInput source="shippingAmount"
                                                     options={{style: 'currency', currency: 'EUR'}}/>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </SimpleForm>
        </Create>
    )
};